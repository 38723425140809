import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslate } from "react-polyglot";
import { useBaseData } from "./BaseDataProvider.jsx";
import { numSumReducer } from "../../Utils/misc.js";
import { useHttp } from "../Common/HttpProvider.jsx";
import useExtractRouteParamInt from "../../hooks/useExtractRouteParamInt.js";
import useEffectWithPreviousValues from "../../hooks/useEffectWithPreviousValues.js";
import useEffectAsync from "../../hooks/useEffectAsync.js";
import { useDialogs } from "../Common/DialogsProvider.jsx";
import { loadObjectFromStorage, saveObjectToStorage } from "../../Utils/StorageUtils.js";

/**
 * @typedef {Object} CourseProviderContext
 * @property {?Level} currentLevel
 * @property {?Branch} currentBranch
 * @property {?LevelsAndReward} levelsAndReward
 * @property {?Branch[]} branches
 * @property {?Branch[]} branchesForLevel
 * @property {?Deck} currentDeck
 * @property {Number} nextDeckId,
 * @property {String} exercisesStatistic
 * @property {String} wordsStatistic
 * @property {String} missionsStatistic
 * @property {String} branchesStatistic
 * @property {EventToProvideReview[]} eventsToFeedback
 * @property {ExercisesTreeForDeck[]} exercisesTreeForDeck
 * @property {?Object} scormProgress
 * @property {FnLevelVoid} setCurrentLevel
 * @property {FnLevelVoid} saveCurrentLevel
 * @property {FnLevelVoid} saveCurrentLevel
 * @property {FnBranchVoid} setCurrentBranch
 * @property {FnBranchVoid} saveCurrentBranch
 * @property {Function} updateCourseData
 * @property {FnEventsToFeedbackVoid} setEventsToFeedback
 * @property {FnAsyncPostFeedbackVoid} postUserReviews
 * @property {FnAsyncObjectVoid} updateScormProgress
 */

/**
 * @type {React.Context<CourseProviderContext>}
 */
const courseContext = createContext({
  /** @type ?Level */
  currentLevel: null,
  /** @type ?Branch */
  currentBranch: null,
  /** @type ?LevelsAndReward */
  levelsAndReward: null,
  /** @type ?Branch[] */
  branches: null,
  /** @type ?Branch[] */
  branchesForLevel: null,
  /** @type ?Deck */
  currentDeck: null,
  nextDeckId: 0,
  exercisesStatistic: "0 / 0",
  wordsStatistic: "0 / 0",
  missionsStatistic: "0 / 0",
  branchesStatistic: "0 / 0",
  /** @type EventToProvideReview[] */
  eventsToFeedback: [],
  /** @type ExercisesTreeForDeck[] */
  exercisesTreeForDeck: [],
  /** @type ?Object */
  scormProgress: null,
  /** @type FnLevelVoid */
  setCurrentLevel: () => {},
  /** @type FnLevelVoid */
  saveCurrentLevel: () => {},
  /** @type FnLevelVoid */
  changeLevel: () => {},
  /** @type FnBranchVoid */
  setCurrentBranch: () => {},
  /** @type FnEventsToFeedbackVoid */
  setEventsToFeedback: () => {},
  /** @type FnBranchVoid */
  saveCurrentBranch: () => {},
  /** @type Function */
  updateCourseData: () => {},
  /** @type FnAsyncPostFeedbackVoid */
  postUserReviews: () => {},
  /** @type FnAsyncObjectVoid */
  updateScormProgress: () => {},
});

/**
 * @returns {CourseProviderContext}
 */
export const useCourseProvider = () => useContext(courseContext);

/**
 * @param {React.ReactNode} children
 * @returns {React.ReactNode}
 * @constructor
 */
const CourseProvider = ({ children }) => {
  const { alert } = useDialogs();
  const snackbar = useDialogs();
  const t = useTranslate();
  const { get, post } = useHttp();
  const history = useHistory();
  const { company, levelsAndReward, counts, getResources, branches, setBranches } = useBaseData();
  const [currentDeck, setCurrentDeck] = useState(/** @type ?Deck */ null);
  const [currentLevel, setCurrentLevel] = useState(/** @type ?Level */ loadObjectFromStorage("current_level") || null);
  const [currentBranch, setCurrentBranch] = useState(
    /** @type ?Branch */ loadObjectFromStorage("current_branch") || null,
  );
  const [branchesForLevel, setBranchesForLevel] = useState(/** @type ?Branch[] */ null);
  const [filteredDecks, setFilteredDecks] = useState(/** @type Deck[] */ []);
  const [nextDeckId, setNextDeckId] = useState(0);
  const [exercisesStatistic, setExercisesStatistic] = useState("0 / 0");
  const [wordsStatistic, setWordsStatistic] = useState("0 / 0");
  const [missionsStatistic, setMissionsStatistic] = useState("0 / 0");
  const [branchesStatistic, setBranchesStatistic] = useState("0 / 0");
  const [eventsToFeedback, setEventsToFeedback] = useState(/** @type EventToProvideReview[] */ []);
  const [exercisesTreeForDeck, setExercisesTreeForDeck] = useState(/** @type ExercisesTreeForDeck[] */ []);
  const [scormProgress, setScormProgress] = useState(/** @type ?Object */ null); // Значение null означает, что для
  // текущего deckId скорм-прогресс ещё не загрузился. Пустой объект означает, что прогресса по данному уроку ещё не
  // зарегистрировано.

  const deckId = useExtractRouteParamInt("/learn/:deckId", "deckId");

  const location = history.location.pathname;

  // clear states
  useEffect(() => {
    if (!company) {
      setBranches(null);
    }
  }, [company]);

  // events for feedback
  useEffectAsync(async () => {
    if (eventsToFeedback.length === 0) {
      /** @type * */
      const resp = await get("userReviews/getEventsToProvideReview");
      if (resp.length > 0) {
        setEventsToFeedback(resp);
      }
    }
  }, []);

  // упражнения сгруппированы по тегам.
  useEffectAsync(async () => {
    if (currentDeck && currentDeck.exercises.items.length > 0) {
      const resp = await get(`exercises/tree/deck?exerciseIds=${JSON.stringify(currentDeck.exercises.items)}`);
      if (resp.length > 0) {
        // переставляем упражнения в последовательности, которая задана в уроке
        const newTree = resp.map((tag) => {
          const newExerciseSequence = [];
          currentDeck.exercises.items.forEach((idExercise) => {
            const itemInTags = tag.exercises.find((item) => item.id === idExercise);
            if (itemInTags) {
              newExerciseSequence.push(itemInTags);
            }
          });
          tag.exercises = newExerciseSequence;
          return tag;
        });
        setExercisesTreeForDeck(newTree);
      }
    }
  }, [currentDeck]);

  // update some data on location change
  useEffectWithPreviousValues(
    ([prevLocation]) => {
      // updateCourseData();
      // refreshUserInfo();
      // eslint-disable-next-line prettier/prettier
      if (prevLocation) {
        // ignore first render
        getResources(["branchesTree", "levels", "counts", "leaders", "userInfo"]).then();
      }
    },
    [location],
  );

  // request deck by deckId if it is specified
  useEffect(() => {
    // При смене урока сразу же надо обnullить прогресс. Это будет означать, что ожидается загрузка следующего урока
    setScormProgress(null);

    if (deckId) {
      requestDeckById(deckId).then();
    } else {
      clearCurrentDeck();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deckId]);

  // set state by deckId specified in path
  useEffect(() => {
    if (!deckId || !branches || !levelsAndReward) {
      return;
    }
    branches.forEach((branch) => {
      branch.decks.forEach((deck) => {
        if (deck.id === deckId) {
          const currentLevel_ = levelsAndReward.levels.find((level) => branch.levelId === level.id);
          const filteredBranches = branches.filter((branch) => branch.levelId === currentLevel_.id);
          saveCurrentBranch(branch);
          saveCurrentLevel(currentLevel_);
          setBranchesForLevel(filteredBranches);
        }
      });
    });
  }, [deckId, branches, levelsAndReward]);

  //filter decks (open, not learned, count > 0)
  useEffect(() => {
    if (currentBranch && currentBranch.decks && currentBranch.decks.length > 0) {
      const newFilteredDecks = currentBranch.decks.filter(
        (deck) =>
          (currentDeck && deck.id === currentDeck.id) ||
          (deck.open &&
            ((deck.exercises.count > 0 && deck.exercises.count !== deck.exercises.learnedCount) ||
              (deck.words.count > 0 && deck.words.count !== deck.words.learnedCount) ||
              (deck.videos.count > 0 && deck.videos.count !== deck.videos.learnedCount))),
      );
      setFilteredDecks(newFilteredDecks);
    }
  }, [currentBranch, currentDeck]);

  //find nextDeckId
  useEffect(() => {
    if (currentDeck && filteredDecks.length > 0) {
      const deckIndex = filteredDecks.findIndex((el) => el.id === currentDeck.id);
      const nextDeckIndex = deckIndex + 1;

      if (nextDeckIndex && filteredDecks.length !== nextDeckIndex) {
        setNextDeckId(filteredDecks[nextDeckIndex].id);
      } else {
        setNextDeckId(0);
      }
    }
  }, [currentDeck, filteredDecks]);

  // init current level when data is available
  useEffect(() => {
    if (!deckId && !currentLevel && levelsAndReward && levelsAndReward.levels && levelsAndReward.levels.length > 0) {
      changeLevel(levelsAndReward.levels[0]);
    }
  }, [levelsAndReward, currentLevel, deckId]);

  // reset level when subject changes
  useEffect(() => {
    if (
      currentLevel &&
      levelsAndReward &&
      levelsAndReward.levels &&
      levelsAndReward.levels.length > 0 &&
      levelsAndReward.levels.findIndex((it) => it.id === currentLevel.id) < 0
    ) {
      saveCurrentLevel(levelsAndReward.levels[0]);
    }
  }, [levelsAndReward]);

  // update current level if server data changed
  useEffect(() => {
    if (!currentLevel || !levelsAndReward) return;

    const levelIndex = levelsAndReward.levels.findIndex((it) => it.id === currentLevel.id);
    if (levelIndex < 0) return;

    saveCurrentLevel(levelsAndReward.levels[levelIndex]);
  }, [levelsAndReward]);

  // filter branches by level when data is available
  useEffect(() => {
    if (branches && currentLevel) {
      const filteredBranches = branches.filter((branch) => branch.levelId === currentLevel.id);
      //console.log("branches", branches);
      //console.log("findIndex", !!filteredBranches.findIndex((branch) => currentBranch.id === branch.id));
      const branchIndex =
        (currentBranch && filteredBranches.findIndex((branch) => currentBranch.id === branch.id)) || -1;
      setBranchesForLevel(filteredBranches);
      //console.log("branchIndex", branchIndex);
      if (filteredBranches.length > 0) {
        saveCurrentBranch(filteredBranches[branchIndex === -1 ? 0 : branchIndex]);
      } else {
        localStorage.removeItem("current_branch");
        setCurrentBranch(null);
      }
    }
  }, [branches, currentLevel, currentBranch]);

  // calculate statistics
  useEffect(() => {
    if (branches && branches.length > 0) {
      /** @type Deck[] */
      const decks = branches.flatMap((branch) => branch.decks);
      let exercisesLearnedCount = 0;
      let exercisesCount = 0;
      if (decks.length !== 0) {
        exercisesLearnedCount = decks.map((deck) => deck.exercises.learnedCount).reduce(numSumReducer);
        exercisesCount = decks.map((deck) => deck.exercises.count).reduce(numSumReducer);
      }
      const branchesCount = branches.length;
      const branchesLearned = branches.filter((branch) => branch.learned).length;
      setExercisesStatistic(`${exercisesLearnedCount} / ${exercisesCount}`);
      setBranchesStatistic(`${branchesLearned} / ${branchesCount}`);
    }
    if (counts) {
      const wordsLearned = counts.words.learnedCount + counts.phrases.learnedCount;
      const wordsTotal = counts.words.count + counts.phrases.count;
      setWordsStatistic(`${wordsLearned} / ${wordsTotal}`);
      setMissionsStatistic(`${counts.missions.learnedCount} / ${counts.missions.count}`);
    }
  }, [branches, counts]);

  // observe paid branch
  useEffectWithPreviousValues(
    /** @param {Branch} prevBranch */
    ([prevBranch]) => {
      if (!currentBranch) {
        return;
      }
      if (!prevBranch || (prevBranch && prevBranch.id !== currentBranch.id)) {
        if (currentBranch.price !== 0 && !currentBranch.purchased) {
          showBuyPrompt(currentBranch);
        }
      }
    },
    [currentBranch],
  );

  /** @param {Branch} branch */
  const showBuyPrompt = (branch) => {
    alert({
      onClose: handleAlertClose(branch),
      title: currentBranch.name,
      message: t("paidBranch"),
      buttonText: t("buyBranch", { price: branch.price, currency: branch.currency }),
    });
  };

  // /** @type FnAsyncVoid */
  // const requestBranches = async () => {
  //   const resp = await get("branchesTree");
  //   if (resp) {
  //     setBranches(resp);
  //   }
  // };

  /** @type FnAsyncNumberVoid */
  const requestDeckById = async (id) => {
    /** @type {Deck} */
    const resp = await get(`decks/${id}`);
    if (resp) {
      setCurrentDeck(resp);
      if (resp.isScorm) {
        const scormProgress_ = await get(`scorm/${id}`);
        setScormProgress(scormProgress_ || {});
      }
    }
  };

  /** */
  const updateCourseData = () => {
    getResources(["branchesTree", "levels", "counts", "leaders"]).then();

    // requestLevels().then();
    // requestBranches().then();
    // requestCounts().then();
    // requestLeaderboard().then();
  };

  /** */
  const clearCurrentDeck = () => {
    setCurrentDeck(null);
  };

  /** @param {Branch} branch */
  const handleAlertClose = (branch) =>
    /** @param {Boolean} byButton */
    (byButton) => {
      if (byButton) {
        showBuyPrompt(branch);
      }
    };

  /** @param {Branch} branch */
  const saveCurrentBranch = (branch) => {
    saveObjectToStorage("current_branch", branch);
    setCurrentBranch(branch);
  };

  /** @param {Level} level */
  const saveCurrentLevel = (level) => {
    saveObjectToStorage("current_level", level);
    setCurrentLevel(level);
  };

  /** @param {Level} level */
  const changeLevel = (level) => {
    localStorage.removeItem("current_branch");
    saveCurrentLevel(level);
  };

  /** @type FnAsyncPostFeedbackVoid */
  const postUserReviews = async (postData) => {
    const resp = await post("userReviews", postData, (error) => {
      snackbar.error(error);
    });
    if (resp) snackbar.success(t("feedbackDialog.submitSuccess"));
  };

  /** @type FnAsyncObjectVoid */
  const updateScormProgress = async (scormData) => {
    const resp = await post(`scorm/${deckId}`, scormData, null, null, true);
    // после отправки прогресса скорм урока надо обновить прогресс в интерфейсе платформы
    await getResources(["branchesTree"], true);
    // После того как прогресс станет видно пользователю, можно показать поздравление о том, что урок выучен, если он
    // действительно выучен
    if (resp.reward === "learned") {
      alert({
        title: t("Exercises.congratulation"),
        message: t("Exercises.congratText"),
        buttonText: "OK",
      });
    }
  };

  /** @type CourseProviderContext */
  const value = {
    currentLevel,
    currentBranch,
    levelsAndReward,
    branches,
    branchesForLevel,
    currentDeck,
    nextDeckId,
    exercisesStatistic,
    wordsStatistic,
    missionsStatistic,
    branchesStatistic,
    eventsToFeedback,
    exercisesTreeForDeck,
    scormProgress,
    setCurrentLevel,
    setCurrentBranch,
    updateCourseData,
    saveCurrentBranch,
    saveCurrentLevel,
    changeLevel,
    setEventsToFeedback,
    postUserReviews,
    updateScormProgress,
  };
  return <courseContext.Provider value={value}>{children}</courseContext.Provider>;
};

export default CourseProvider;
