import PropTypes from "prop-types";
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { useTranslate } from "react-polyglot";
import { makeStyles, Typography, IconButton, DialogTitle, DialogActions, DialogContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ic_red_person from "../../../assets/ic_red_person.png";
import FlexboxVertical from "../../../SharedComponents/FlexboxVertical.jsx";
import PrimaryRoundedButton from "../../../SharedComponents/PrimaryRoundedButton.jsx";
import GreyButton from "../../../SharedComponents/GreyButton.jsx";
import { useAuth } from "../../../Providers/Auth/AuthProvider.jsx";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialog-paper": {
      borderRadius: 16,
      height: 360,
    },
    "& .MuiButton-root": {
      textTransform: "none",
      width: "45%",
    },
    "& 	.MuiDialogActions-root": {
      justifyContent: "space-around",
    },
  },
  title: {
    margin: 0,
  },
  contentFlex: {
    alignItems: "center",
    color: "#49454F", // todo перенести этот цвет обратно в тему, когда 13-ый тип упражнения будет слит в мастер
    marginTop: 30,
  },
  icon: {
    display: "block",
    width: 42,
    height: 42,
    objectFit: "contain",
    marginLeft: "auto",
    marginRight: "auto",
  },
  text: {
    margin: "20px 0",
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0,
    color: "#1B1C1C",
  },
  okButton: {
    backgroundColor: "#CF2965",
    "&:hover": {
      backgroundColor: "#CF2965",
      opacity: 0.8,
    },
  },
}));

/**
 * @param {Boolean} open
 * @param {Function} onClose
 * @param {Function} onSubmit
 */
const DemoRegistrationPopUp = ({ open, onClose }) => {
  const classes = useStyles();
  const t = useTranslate();
  const { register, checkEmailResponse } = useAuth();

  const regexName = /^([^@]+)/;

  /** */
  const cancel = () => {
    onClose();
  };

  /** */
  const onSubmit = async () => {
    const name = checkEmailResponse.userEmail.match(regexName)[1];
    const password = "DemoAccess";
    const subjectId = checkEmailResponse.company.id;

    try {
      await register(name, null, null, password, subjectId, null);
    } catch (e) {
      console.log(e);
    }
    onClose();
  };

  return (
    <Dialog className={classes.root} open={open} onClose={cancel} maxWidth="xs" fullWidth>
      <DialogTitle disableTypography className={classes.title}>
        <IconButton aria-label="close" className={classes.closeButton} onClick={cancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <img className={classes.icon} src={ic_red_person} alt="" />
        <FlexboxVertical className={classes.contentFlex}>
          <Typography className={classes.text}>{t("demoAccess")}</Typography>
        </FlexboxVertical>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <GreyButton onClick={cancel}>{t("back")}</GreyButton>
        <PrimaryRoundedButton onClick={onSubmit} className={classes.okButton}>
          {t("accessDemoButton")}
        </PrimaryRoundedButton>
      </DialogActions>
    </Dialog>
  );
};

DemoRegistrationPopUp.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  // onSubmit: PropTypes.func.isRequired,
};

export default DemoRegistrationPopUp;
