import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import grey from "@material-ui/core/colors/grey.js";
import ReactCountryFlag from "react-country-flag";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import logo from "../../assets/logo.svg";
import studyLessLogo from "../../assets/study-less-512x512.png";
import rusLangIcon from "../../assets/ic_rus_lang.svg";
import uzLangIcon from "../../assets/UZ_flag.png";
import FlexboxVertical from "../../SharedComponents/FlexboxVertical.jsx";
import FlexboxHorizontal from "../../SharedComponents/FlexboxHorizontal.jsx";
import { useLocale } from "../../Providers/i18n/I18nProvider.jsx";
import CheckEmail from "./Components/CheckEmail.jsx";
import { useAuth } from "../../Providers/Auth/AuthProvider.jsx";
import Password from "./Components/Password.jsx";
import Registration from "./Components/Registration.jsx";
import DemoRegistrationPopUp from "./Components/DemoRegistrationPopUp.jsx";
import useEffectWithPreviousValues from "../../hooks/useEffectWithPreviousValues.js";
import { useBaseData } from "../../Providers/Data/BaseDataProvider.jsx";
import useEffectAsync from "../../hooks/useEffectAsync.js";
import useCompanyPublicId from "../../hooks/useCompanyPublicId.js";
import { IS_APP_STUDYLESS } from "../../constants.js";
import useTitle from "../../hooks/useTitle.js";
import useFavicon from "../../hooks/useFavicon.js";
import ViewportMeta from "../../SharedComponents/ViewportMeta.jsx";

const useStyles = makeStyles({
  boxRoot: {
    backgroundColor: grey[50],
    minHeight: "100vh",
    "& .MuiTextField-root": {
      backgroundColor: grey[200],
      margin: "30px 20px 0",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 0,
    },
  },
  logo: {
    maxHeight: 130,
  },
  tabs: {
    marginTop: 31,
    backgroundColor: grey[200],
    "& .Mui-selected": {
      fontWeight: 800,
    },
    "& .MuiTab-root": {
      fontSize: 14,
    },
  },
  langs: {
    margin: "40px 0",
  },
  form: {
    backgroundColor: "white",
    "& button": {
      marginTop: 30,
    },
  },
});

const useFlagsStyles = makeStyles((theme) => ({
  flagRoot: {
    objectFit: "cover",
    borderRadius: "50%",
  },
  active: {
    boxShadow: `0px 0px 0px 4px ${grey[50]}, 0px 0px 0px 8px ${theme.palette.primary.dark}`,
  },
}));

//because ReactCountryFlag overrides styles
const flagInlineStyles = { width: 40, height: 40 };

/** */
const LangsSwitcher = (props) => {
  const classes = useFlagsStyles();
  const { locale, setLocale, localeCodes } = useLocale();

  useEffect(() => {
    document.body.style.overflow = "auto";

    return () => {
      document.body.style.overflow = null;
    };
  }, []);

  /** @param {String} lang */
  const changeLocale = (lang) => () => {
    setLocale(lang);
  };

  return (
    <FlexboxHorizontal {...props}>
      {localeCodes.includes("en") && (
        <IconButton onClick={changeLocale("en")}>
          <ReactCountryFlag
            className={clsx(classes.flagRoot, { [classes.active]: locale === "en" })}
            countryCode="GB"
            svg
            style={flagInlineStyles}
          />
        </IconButton>
      )}
      {localeCodes.includes("fr") && (
        <IconButton onClick={changeLocale("fr")}>
          <ReactCountryFlag
            className={clsx(classes.flagRoot, { [classes.active]: locale === "fr" })}
            countryCode="FR"
            svg
            style={flagInlineStyles}
          />
        </IconButton>
      )}
      {localeCodes.includes("uk") && (
        <IconButton onClick={changeLocale("uk")}>
          <ReactCountryFlag
            className={clsx(classes.flagRoot, { [classes.active]: locale === "uk" })}
            countryCode="UA"
            svg
            style={flagInlineStyles}
          />
        </IconButton>
      )}
      {localeCodes.includes("ru") && (
        <IconButton onClick={changeLocale("ru")}>
          <img
            src={rusLangIcon}
            className={clsx(classes.flagRoot, { [classes.active]: locale === "ru" })}
            style={flagInlineStyles}
            alt=""
          />
        </IconButton>
      )}
      {localeCodes.includes("uz") && (
        <IconButton onClick={changeLocale("uz")}>
          <img
            src={uzLangIcon}
            className={clsx(classes.flagRoot, { [classes.active]: locale === "uz" })}
            style={flagInlineStyles}
            alt=""
          />
        </IconButton>
      )}
      {localeCodes.includes("de") && (
        <IconButton onClick={changeLocale("de")}>
          <ReactCountryFlag
            className={clsx(classes.flagRoot, { [classes.active]: locale === "de" })}
            countryCode="DE"
            svg
            style={flagInlineStyles}
          />
        </IconButton>
      )}
    </FlexboxHorizontal>
  );
};

/** */
const AuthPage = () => {
  const { companyName, domain } = useCompanyPublicId();
  const classes = useStyles();
  const history = useHistory();
  /** @type {String} */
  const location = history.location.pathname;
  const { checkEmailResponse, user, signOut, company2, company } = useAuth();
  const { preselectedSubject, chooseSubject, getAvailableSubjects } = useBaseData();

  const [openDemoDialog, setOpenDemoDialog] = useState(false);

  const companyLogo = IS_APP_STUDYLESS ? studyLessLogo : logo;

  useTitle("title.login", {
    school: company2?.name || company?.name || (IS_APP_STUDYLESS ? "Study Less" : "Skillzrun"),
  });

  useFavicon();

  // reset checking email data when user comes back from anywhere
  useEffectWithPreviousValues(
    ([prevLocation]) => {
      if (!companyName && !domain && !/\/auth$/.test(prevLocation) && /\/auth$/.test(location) && checkEmailResponse) {
        // Разлогин только для общего скилзрана (!companyName && !domain)
        signOut();
      }
    },
    [location],
  );

  // go to subject select if logged in or successfully registered
  useEffectAsync(async () => {
    if (user) {
      // if (preselectedOffer && schoolName) {
      //   history.replace(`/offer/${schoolName}/${preselectedOffer}`);
      // } else
      if (user.subjectId === -1) {
        if (preselectedSubject) {
          history.replace(`/subjects/${preselectedSubject}`);
        } else {
          history.replace("/subjects");
        }
      } else if ((domain || companyName) && preselectedSubject) {
        const availableSubjects = await getAvailableSubjects();

        const exist = availableSubjects && availableSubjects.find((available) => available.id === preselectedSubject);

        if (exist !== undefined && exist.added) {
          if (await chooseSubject(preselectedSubject)) {
            history.replace("/");
          }
        } else {
          history.replace("/");
        }
      } else if (!domain && !companyName && preselectedSubject && (await chooseSubject(preselectedSubject))) {
        history.replace("/");
      } else {
        history.replace("/subjects");
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // auth routes resolver
  useEffectWithPreviousValues(
    ([_, prevLocation]) => {
      if (
        /\/auth$/.test(prevLocation) &&
        /\/auth$/.test(location) &&
        checkEmailResponse &&
        (!checkEmailResponse.created || checkEmailResponse.invited)
      ) {
        // ==== Если школа Скиллзран, то через диалоговое окно можно зарегистрироваться и получить демо-доступ ====
        if (checkEmailResponse.company.id === 1) {
          setOpenDemoDialog(true);
          return;
        }
        // ===================================================================================

        history.push(`${companyName ? "/" + companyName : ""}/registration`);
        return;
      }
      if (
        /\/auth$/.test(prevLocation) &&
        /\/auth$/.test(location) &&
        checkEmailResponse &&
        checkEmailResponse.created &&
        !checkEmailResponse.invited
      ) {
        history.push(`${companyName ? "/" + companyName : ""}/password`);
      }
      if (
        /\/registration$/.test(location) &&
        (!checkEmailResponse || (checkEmailResponse.created && !checkEmailResponse.invited))
      ) {
        if (history.length > 2) {
          history.goBack();
        } else {
          history.replace(`${companyName ? "/" + companyName : ""}/auth`);
        }
      }
    },
    [checkEmailResponse, location],
  );

  return (
    <>
      <ViewportMeta
        // Жёсткая фиксация масштаба страницы для мобильных браузеров
        initialScale={0.7}
      />
      <FlexboxVertical className={classes.boxRoot} alignItems="center" justifyContent="center">
        <img
          className={classes.logo}
          src={(company && company.logo.url) || (company2 && company2.logo.url) || companyLogo}
          alt="logo"
        />
        <LangsSwitcher className={classes.langs} />
        <FlexboxVertical width={413} marginTop="20px">
          <Switch>
            <Route exact path={["/auth", "/:companyName/auth"]}>
              <CheckEmail className={classes.form} />
            </Route>
            <Route exact path={["/password", "/:companyName/password"]}>
              <Password className={classes.form} />
            </Route>
            <Route exact path={["/registration", "/:companyName/registration"]}>
              <Registration className={classes.form} />
            </Route>
          </Switch>
        </FlexboxVertical>
      </FlexboxVertical>
      <DemoRegistrationPopUp open={openDemoDialog} onClose={() => setOpenDemoDialog(false)} />
    </>
  );
};

export default AuthPage;
