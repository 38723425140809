import React from "react";
import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";

export default withStyles((theme) => ({
  root: {
    borderRadius: 50,
  },
  outlined: {
    "& .MuiButton-label": {
      fontWeight: 300,
      fontSize: 24,
      [theme.breakpoints.between(1024, 1320)]: {
        fontSize: 20,
      },
    },
  },
  outlinedPrimary: {
    borderWidth: 2,
    "&:hover": {
      borderWidth: 2,
    },
  },
  //eslint-disable-next-line react/display-name
}))(React.forwardRef((props, ref) => <Button {...props} ref={ref} color="primary" variant="outlined" />));
