import React, { createContext, useContext, useState } from "react";
import { loadObjectFromStorage, saveObjectToStorage } from "../../Utils/StorageUtils.js";

/**
 * @typedef {Object} UserPreferencesProviderContext
 * @property {String} lang
 * @property {FnStringVoid} setLang
 * @property {Function} clear
 */

/** @type {React.Context<UserPreferencesProviderContext>} */
const preferencesContext = createContext({
  lang: "en",
  /** @type FnStringVoid */
  setLang: () => {},
  clear: () => {},
});

/** @returns {UserPreferencesProviderContext} */
export const useUserPreferences = () => useContext(preferencesContext);

const defaultPreferences = {};

/** @param {React.ReactNode} children */
const UserPreferencesProvider = ({ children }) => {
  const [preferences, setPreferences] = useState(loadObjectFromStorage("user_prefs") || defaultPreferences);

  /** @type {FnStringVoid} */
  const setLang = (newLang) => {
    const newPreferences = { ...preferences };
    newPreferences.lang = newLang;
    saveObjectToStorage("user_prefs", newPreferences);
    setPreferences(newPreferences);
  };

  /** */
  const clear = () => {
    localStorage.removeItem("user_prefs");
    setPreferences(defaultPreferences);
  };

  /** @type UserPreferencesProviderContext */
  const value = {
    lang: preferences.lang || "en",
    setLang,
    clear,
  };

  return <preferencesContext.Provider value={value}>{children}</preferencesContext.Provider>;
};

export default UserPreferencesProvider;
