import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Drawer,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  IconButton,
  Link,
} from "@material-ui/core";
import FormatIndentIncreaseIcon from "@material-ui/icons/FormatIndentIncrease";
import { useTranslate } from "react-polyglot";
import { useHistory } from "react-router-dom";
import { makeDefaultTitle } from "../../../../../Utils/misc.js";
import { useCourseProvider } from "../../../../../Providers/Data/CourseProvider.jsx";
import { useLearnProcess } from "../../../../../Providers/Data/LearnProcessProvider.jsx";
import { useBaseData } from "../../../../../Providers/Data/BaseDataProvider.jsx";
import icCheck from "../../../../../assets/ic_check_green.svg";

const useStyles = makeStyles(() => ({
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: "#fafafa",
    borderRadius: 10,
    width: 260,
    height: "calc(100vh - 125px)",
    marginTop: 120,
    overflowY: "auto",
    "& .MuiAccordion-root.Mui-expanded": {
      margin: 0,
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      backgroundColor: "#f5f5f5",
    },
    "& .MuiAccordionSummary-content": {
      alignItems: "center",
    },
    "& .MuiAccordionDetails-root": {
      alignItems: "center",
      padding: 8,
    },
    "& .MuiLink-underlineHover": {
      cursor: "pointer",
      opacity: 0.7,
      "&:hover": {
        textDecoration: "none",
        opacity: 1,
      },
    },
  },
  buttonClose: {
    margin: "8px 0",
    width: 50,
    height: 50,
  },
}));

/** */
const ExercisesTreeByTags = ({ open, setOpen }) => {
  const classes = useStyles();
  const history = useHistory();
  const t = useTranslate();
  const [expanded, setExpanded] = useState("");
  const [currentDeckProgress, setCurrentDeckProgress] = useState(null);
  const { currentDeck, exercisesTreeForDeck } = useCourseProvider();
  const { exercise: currentExercise } = useLearnProcess();
  const { branches } = useBaseData();

  // currentDeckProgress необходим для индикации выученного упражнения, обновляется после каждого ответа на упражнение
  useEffect(() => {
    if (branches && currentDeck && currentExercise) {
      const branch = branches.find((branch) => branch.decks.find((deck) => deck.id === currentDeck.id));
      setCurrentDeckProgress(branch.decks.find((deck) => deck.id === currentDeck.id));
    } else {
      setCurrentDeckProgress(null);
    }
  }, [branches, currentExercise, currentDeck]);

  /**  */
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  /**
   * @param {[Object]} exercisesOfTag
   * @param {[Number]} learnedExercises
   * @returns {string}
   */
  const makeProgressText = (exercisesOfTag, learnedExercises) => {
    const progressOfTags =
      exercisesOfTag.filter((item) => learnedExercises.includes(item.id)).length / exercisesOfTag.length;

    return `(${Math.floor(progressOfTags * 100)}%)`;
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={open}
      onClose={() => setOpen(!open)}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <IconButton color="primary" className={classes.buttonClose} onClick={() => setOpen(!open)}>
        <FormatIndentIncreaseIcon />
      </IconButton>
      {exercisesTreeForDeck.map((tag) => (
        <Accordion key={tag.id} square expanded={expanded === tag.name} onChange={handleChange(tag.name)}>
          <AccordionSummary>
            {tag.image && <img src={tag.image.url} alt="Tags image" width={24} height={24} />}
            <Typography style={{ marginLeft: tag.image ? 16 : 40 }}>{tag.name}</Typography>
            {currentDeckProgress && (
              <Typography style={{ marginLeft: 4, fontSize: "0.9rem" }}>
                {makeProgressText(tag.exercises, currentDeckProgress.exercises.learnedItems)}
              </Typography>
            )}
          </AccordionSummary>
          {tag.exercises.map((exercise) => (
            <AccordionDetails key={exercise.id}>
              {currentDeckProgress && currentDeckProgress.exercises.learnedItems.find((id) => id === exercise.id) && (
                <img width={12} height={12} src={icCheck} alt="" />
              )}
              <Link
                color="inherit"
                variant="body1"
                style={{ marginLeft: exercise.learned ? 4 : 16 }}
                onClick={() => history.push(`/learn/${currentDeck.id}/exercise/${exercise.id}`)}
              >
                <Typography
                  style={{
                    color: currentExercise.exercise.id === exercise.id ? "black" : "inherit",
                    opacity: currentExercise.exercise.id === exercise.id ? 1 : "inherit",
                  }}
                >
                  {exercise.title || t(makeDefaultTitle(exercise.type, exercise.answerType))}
                </Typography>
              </Link>
            </AccordionDetails>
          ))}
        </Accordion>
      ))}
    </Drawer>
  );
};
ExercisesTreeByTags.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default ExercisesTreeByTags;
