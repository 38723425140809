export default {
  title: {
    login: "%{school} - Вход",
    registration: "%{school} - Регистрация",
    subjects: "%{school} - Выбор предмета",
    subject: "%{school} - Предмет",
    offer: "%{school} - Предложение",
    home: "%{school} - Домашняя страница",
    dictionary: "%{school} - Словарь",
    events: "%{school} - События",
    news: "%{school} - Новости",
    profile: "%{school} - Профиль",
    learn: "%{school} - Обучение",
    pay: "%{school} - Оплата предмета",
    checkInSystem: "Укажите ваш Email",
    setPassword: "Введите пароль чтобы войти.",
    createAccount: "Укажите/Обновите даные вашей учетной записи.",
  },
  email: "Email",
  personalLink: "Персональная ссылка",
  login: "Логин",
  name: "Имя",
  firstLastName: "Фамилия и имя", // todo
  phone: "Телефон",
  formatPhone: "Номер телефона в формате 998…", // todo for leader-online
  aboutMe: "Обо мне",
  fullAge: "Ваш возраст (полных лет)", // todo
  errorAge: "некорректный возраст", // todo
  notNumber: "значение должно быть числом", // todo
  registration: "Регистрация",
  password: "Пароль",
  currentPassword: "Текущий пароль",
  newPassword: "Новый пароль",
  confirmPassword: "Повторите пароль",
  confirmNewPassword: "Повторите новый пароль",
  forgotPassword: "Забыли пароль?",
  passwordsMatch: "Пароли не совпадают",
  resetPassword: "Пароль сброшен успешно, проверьте свой Email.",
  resetConfirm: "Вы желаете сбросить пароль?",
  enter: "Войти",
  studentEnter: "Вход для учеников",
  check: "Дальше",
  emailIsNotRegistered: "Пользователя с таким адресом не существует",
  logout: "Выйти",
  cancel: "Отменить",
  yes: "Да",
  no: "Нет",
  course: "Курс",
  dictionary: "Словарь",
  events: "События",
  news: "Новости",
  words: "Слова",
  wordsAreHere: "Эти слова находятся здесь:",
  allWords: "Все слова",
  phrases: "Фразы",
  videos: "Видео",
  exercises: "Упражнения",
  theory: "Теория",
  theoryAlt: "Конспект",
  theoryAdditionalFile: "Скачать дополнительный файл (%{filename})",
  topic: "Топик",
  lesson: "Урок",
  profile: "Профиль",
  orders: "Покупки",
  payment: "Оплата",
  leaderboard: "Лидеры",
  time: "Время",
  points: "Баллы",
  courses: "Мои курсы",
  loading: "Загрузка...",
  noTheory: "К этому уроку нет теории",
  startTraining: "Начать тренировку",
  missions: "Миссии",
  branches: "Разделы",
  easy: "Легко",
  expert: "Эксперт",
  wordToLearn: "Выучить позже",
  iKnowWord: "Я знаю это слово",
  videoVocabulary: "Видео лексика",
  passed: "пройдено",
  unsupportedExercise: "Данный тип упражнений (%{type}) пока не поддерживается",
  changeCourse: "Сменить курс",
  currentCourse: "Текущий курс",
  allSubjects: "Все предметы",
  subject: "Предмет",
  group: "Группа",
  start: "Начать",
  chooseSubject: "Выберите предмет, который вы хотите изучать",
  hourValue: "%{value} ч",
  completed: "Завершено",
  notCompleted: "Не завершено",
  paidBranch: "Эта тема платная. Пожалуйста, купите её, чтобы учиться.",
  buyBranch: "Купить тему за %{price} %{currency}",
  paidSubject: "Этот предмет платный. Пожалуйста, купите его, чтобы учиться.",
  buySubject2: "Купить предмет за %{price} %{currency}",
  decksItemNotOpen: "Чтобы открыть этот урок, вам нужно пройти предыдущий урок",
  decksItemNotPurchasedBranch: "The topic of this lesson is blocked",
  decksItemNotOpenLevel: "Пройдите предыдущий уровень, чтобы открыть урок",
  decksItemNotOpenBranch: "Чтобы открыть этот урок, вам нужно пройти предыдущую тему",
  deckAccessDenied: "Этот урок для вас ещё не доступен",
  openOnDate: "откроется %{date}", // todo
  levelAccessIsDenied: "Материалы этого уровня вам не доступны",
  youAreDeactivated: "Ваш аккаунт был деактивирован, пожалуйста, свяжитесь с вашей школой",
  lockText: {
    p1: "Упс... К сожалению, вы не можете получить доступ к учебным материалам. Работа школы временно приостановлена.",
    p2: "Для получения подробной информации и решения всех вопросов обратитесь к представителям школы.",
  },
  youAreDeactivatedShort: "Ваш аккаунт деактивирован",
  youAreHaveNotPaidOffers: "У вас есть неоплаченные предложения",
  yourCertificates: "Ваши сертификаты",
  emptyDeck: "Урок пуст",
  nextDeck: "Следующий урок",
  next: "Далее",
  proceed: "Продолжить",
  skip: "Пропустить",
  more: "Ещё",
  exit: "Выход",
  details: "Подробнее",
  go: "Перейти",
  profileEdit: "Редактирование профиля",
  userInfo: "Информация о пользователе",
  of: "из",
  back: "Назад",
  levelPackExist: "У вас уже есть этот набор уровней",
  awaitingPayment: "Ожидает оплату",
  bought: "Купленный",
  boughtUntilDate: "Купленный до %{endDate}",
  availableUntilDate: "доступный до %{endDate}",
  showMore: "показать больше",
  hide: "свернуть",
  communication: "Связь со школой",
  askQuestion: "Задать вопрос",
  noDescription: "Описание отсутствует",
  onePerMonth: "1 per Month", //toDo
  semiannually: "Semiannually", //toDo
  onceAYear: "Once a year", //toDo
  stopSubscribe: "Stop Subscribe", // todo
  enable: "Enable Subscribe", // todo
  expired: "Expired", //toDo
  buySubject: "Купить предмет",
  buyOffer: "Купить предложение",
  subscribeOffer: "Subscribe offer", // todo
  firstPayment: "Первый платеж",
  accessDaysWarning: "Остался %{smart_count} день |||| Осталось %{smart_count} дней",
  accessDemoDaysWarning: "Демо: Остался %{smart_count} день |||| Демо: Осталось %{smart_count} дней",
  accessDemoButton: "Демо доступ",
  closeButton: "Закрыть",
  existOffer: "There is already an unpaid offer", // todo
  Exercises: {
    tabTitle: "Упражнения",
    skip: "Пропустить",
    check: "Проверить",
    typeAnswer: "Введите свой ответ",
    next: "Далее",
    rightAnswerWas: "Правильный ответ был:",
    congratulation: "Поздравляем!",
    congratText: "Вы выучили все упражнения в этом уроке!",
    wrongAnswer: "Ваш ответ неверный",
    answerSaved: "Ответ сохранен",
    commentToAnswer: "Комментарий к выбранному ответу:", // todo
    appendix: {
      pdf: {
        tooltipButton: "Дополнительные материалы",
      },
    },
    type13: {
      title: "Заполните пропуски, выбрав нужное слово из выпадающего списка",
    },
    type12: {
      title: "Ознакомьтесь с дополнительной информацией", // todo
    },
    type11: {
      title: {
        select: "Выберите правильный ответ",
        keyboard: "Напишите правильный ответ",
      },
    },
    type9: {
      title: {
        select: "Заполните пропуски, выбрав нужное слово",
        keyboard: "Заполните пропуски",
      },
    },
    type8: {
      title: "Найдите пары",
    },
    type7: {
      title: {
        text: "Напишите ваш ответ",
        audio: "Запишите аудио ответ",
      },
      recordingIsNotSupported: "Запись звука не поддерживается вашим браузером",
      audioRecordingError: "Ошибка записи звука: ",
      inputLabel: "Ваш ответ",
      addPhoto: "Добавить фото/видео (до 5 шт.)",
      cameraInitialization: "Инициализация...",
      change: "Изменить",
      codeToApprove: "Пароль",
      homework: {
        teacherData: {
          audio: "Аудио комментарий преподавателя",
          audioComment: "Голосовой комментарий преподавателя",
        },
      },
      addFiles: "Добавить файлы",
      limitsHelperText: "Длина текста должна быть не менее %{count} букв",
    },
    type6: {
      title: "Нарисуйте элемент",
      checkButtonText: "Понятно!",
    },
    type3: {
      title: "Расположите элементы в правильном порядке",
      hint: "Перетащите сюда элементы, расположенные ниже",
    },
    type5: {
      title: "Выберите элементы",
      hint: "Выберите нужные элементы внизу экрана (чтобы выбрать — просто нажмите на них)",
    },
    type2: {
      title: "Выберите пропущенное слово",
    },
  },
  Events: {
    headNotice: "Время события отображается в соответствии с вашим часовым поясом",
    title: "События этой недели",
    startTime: "Время начала",
    group: "Группа",
    persons: "%{smart_count} человек |||| %{smart_count} человек",
    leader: "Ведущий",
    tag: "Категория",
    joinedCheckbox: "С регистрацией",
    joined: "Выбрано:",
    interested: "Регистрация",
    enter: "Войти",
    limitPopupInfo: "Вы достигли лимита регистраций на события",
    eventHasStarted: "Событие уже началось!",
    registrationNotice: "Вы успешно зарегистрировались! Кнопка входа будет активна в %{date_and_time}. После этого времени вы не сможете отменить регистрацию на событие",
    registrationNoticeNoUrl: "Вы успешно зарегистрировались! После %{date_and_time} вы не сможете отменить регистрацию на событие",
    cancelRegistration: "Вы действительно хотите отменить регистрацию?",
    calendarLink: "Добавить событие в Google календарь",
  },
  statistic: {
    title: "Статистика",
    points: "баллы",
    phrases: "фразы",
    missions: "миссии",
    words: "слова",
    time: "время",
  },
  status: {
    wait_review: "Ожидает проверки",
    on_review: "На проверке",
    approved: "Принято",
    not_approved: "Отклонено",
    not_approved_allow_update: "Отклонено (Переделать)",
    ai_reviewed: "Проверено с помощью ИИ",
  },
  homeworks: {
    tabName: "Домашки",
    todo: "К выполнению",
    done: "Выполненные",
    topic: "Тема",
    lesson: "Урок",
    onlyInMobileApp: "Доступно только в мобильном приложении",
    todoHomework: "ЗАДАНИЕ НА ПРОВЕРКЕ",
    postToDoButton: "Отправить",
    types: {
      homework_todo: "ToDo",
      exercise: "Exercise",
    },
  },
  exam: {
    popupTitle: "Урок с экзаменом",
    popupDescr1: "Это урок с экзаменом. Сначала изучите все дополнительные материалы и только после переходите к выполнению упражнений.",
    popupDescr2: 'Как только вы перейдете во вкладку "Упражнения" начнется отсчет времени.',
    popupDescr3: 'Вкладки "Теория" и "Видео" НЕ запускают отсчет времени экзамена.',
    cancelButton: "В следующий раз",
    positiveButton: "Понятно",
    questions: "%{smart_count} вопрос |||| %{smart_count} вопросов",
    oneAttempt: "Одна попытка на ответ",
    timeLimit: "Ограничение по времени: %{limit} минут",
    noPause: "Экзамен не может быть приостановлен",
    progress: "Вопрос №%{current} из %{total}",
    closeButton: "Закрыть",
    nextButton: "Продолжить",
    finishedPopupTitle: "Результат экзамена",
    examPassed: "Экзамен пройден",
    examNotPassed: "Экзамен не пройден",
    waitHomeworks: "%{smart_count} вопрос на проверке |||| %{smart_count} вопросов на проверке",
  },
  subjects: {
    unavailable: "В данный момент предмет недоступен",
  },
  orderStatus: {
    approved: "Принято",
    canceled: "Отклонено",
    pending: "Ожидает",
  },
  notificationsTypes: {
    event: "Событие",
    invoice: "Счёт",
    news: "Новости",
    homework: "homework", //todo
    homework_todo: "homework_todo", //todo
    type: {
      exercise: "Homework", //todo
      homework_todo: "Homework To do", //todo
    },
  },
  notificationsAlert: {
    subscribe: "Включить уведомления",
    unsubscribe: "Выключить уведомления",
    instructions: "Нажмите на замочек 🔒 слева от адресной строки и измените разрешение уведомлений как показано на изображении или измените общие настройки браузера.",
    permissionDefault: {
      title: "Подпишитесь на уведомления 🔔",
      body: "Оповещения о сменене статуса ДЗ, начале события, новости, новом задании к выполнению. Никакой рекламы, только уведомления о вашем обучении!",
    },
    permissionDenied: {
      title: "Сейчас оповещения выключены ❌",
      body: "Ранее вы отказались от уведомлений. Без них вы не можете вовремя узнавать о сменене статуса ДЗ, начале события. Чтобы включить уведомления следуйте инструкциям ниже:",
    },
    permissionGranted: {
      title: "Сейчас оповещения включены ✔",
      body: "Вы точно хотите отказаться от уведомлений? Без них вы не можете вовремя узнавать о сменене статуса ДЗ, начале события. Чтобы выключить уведомления следуйте инструкциям ниже:",
    },
  },
  feedbackDialog: {
    sendFeedback: "Отправить отзыв", // todo
    placeholder: "Расскажите о ваших впечатлениях", // todo
    defaultInstruction: "Используйте слайдер чтобы оценить", // todo
    eventInstruction: "Оцените как прошло событие", // todo
    submitError: "Выберите пожалуйста оценку", // todo
    submitSuccess: "Спасибо! Ваш отзыв успешно отправлен", // todo
    wasNotPresent: "Я не посещал событие", // todo
    dateOfEvent: "Дата события:", // todo
  },
  congratulation: {
    mission: "Поздравляем, миссия %{name} завершена!",
  },
  accountNotFound: "Этот адрес электронной почты не связан ни с одним образовательным пространством. Свяжитесь с администратором вашей школы",
  demoAccess: "Хотите попробовать SkillzRun для своего бизнеса/школы? Зарегистрируйтесь для тестирования платформы", // todo
};
