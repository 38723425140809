import React, { createContext, useState, useMemo } from "react";
import { ThemeProvider, createMuiTheme, darken, fade } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import "overlayscrollbars/css/OverlayScrollbars.css";

/**
 * @type {React.Context<{theme: Object,
    setThemeColor: function,
    resetTheme: function,
    setFontFamily: function,
    resetFont: function}>}
 */
export const ThemeContext = createContext();

const defaultPrimaryColor = "#53AE67";
const defaultFontFamily = "Montserrat, sans-serif";

/** */
const MyThemeProvider = ({ children }) => {
  const [primaryColor, setPrimaryColor] = useState(defaultPrimaryColor);
  const [fontFamily, setFontFamily] = useState(defaultFontFamily);

  const theme = useMemo(() => {
    const baseTheme = createMuiTheme(); // шаг 1: получаем базовую тему

    return createMuiTheme({
      ...baseTheme,
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 1024,
          lg: 1320,
          xl: 1920,
        },
      },
      typography: {
        fontFamily,
      },
      palette: {
        primary: {
          main: primaryColor,
          dark: darken(primaryColor, 0.2),
          light: fade(primaryColor, 0.6),
        },
        secondary: {
          main: "#41A1F9",
          dark: "#004A80",
        },
        error: {
          main: red.A400,
        },
        background: {
          default: "#fff",
        },
      },
      overrides: {
        MuiButtonBase: {
          root: {
            "&$disabled": {
              opacity: 0.4,
            },
          },
        },
        MuiButton: {
          containedPrimary: {
            color: "white",
            borderRadius: 0,
            "& .MuiButton-label": {
              fontWeight: 800,
              fontSize: 18,
            },
          },
          containedSecondary: {
            color: "white",
          },
          containedSizeSmall: {
            "& .MuiButton-label": {
              fontWeight: 500,
              fontSize: 12,
            },
          },
        },
        MuiTabs: {
          indicator: {
            height: 4,
          },
        },
        MuiTypography: {
          body2: {
            fontSize: 12,
          },
          subtitle2: {
            fontSize: 12,
            fontWeight: 900,
          },
          caption: {
            fontSize: 12,
            fontWeight: 700,
          },
        },
        MuiCardContent: {
          root: {
            "&:last-child": {
              paddingBottom: 16,
            },
          },
        },
        MuiCardActionArea: {
          root: {
            height: "100%",
          },
        },
        MuiAccordion: {
          root: {
            "&$disabled": {
              backgroundColor: "rgba(0,0,0,0)",
            },
          },
        },
        MuiMenuItem: {
          root: {
            minHeight: baseTheme.spacing(4),
            [baseTheme.breakpoints.up("sm")]: {
              minHeight: baseTheme.spacing(4),
            },
          },
        },
      },
    });
  }, [primaryColor, fontFamily]);

  /**
   * @param {string} color
   */
  const setThemeColor = (color) => {
    if (color.substr(0, 1) !== "#") color = `#${color}`;
    setPrimaryColor(color);
  };

  /** */
  const resetTheme = () => {
    setPrimaryColor(defaultPrimaryColor);
  };

  /** */
  const resetFont = () => {
    setFontFamily(defaultFontFamily);
  };

  const value = {
    theme,
    setThemeColor,
    resetTheme,
    setFontFamily,
    resetFont,
  };

  return (
    <ThemeContext.Provider value={value}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default MyThemeProvider;
