import React, { useEffect, useRef, useState } from "react";
import { Box, Checkbox, FormControlLabel, makeStyles, Table, TableBody, TableContainer } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { useTranslate } from "react-polyglot";
import Event from "./Event.jsx";
import Carousel from "../../../SharedComponents/Carousel.jsx";
import { useEvents } from "../../../Providers/Data/EventsProvider.jsx";
import useDateUtils from "../../../hooks/useDateUtils.js";
import useExtractRouteParamInt from "../../../hooks/useExtractRouteParamInt.js";
import useCompanyTitle from "../../../hooks/useCompanyTitle.js";
import EventRow from "./EventRow.jsx";
import StringSelectInput from "../../../SharedComponents/StringSelectInput.jsx";

const useStyles = makeStyles((theme) => ({
  headText: {
    color: theme.palette.secondary.dark,
    margin: "15px 20px 0",
  },
  eventsWrapper: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
    overflowX: "hidden",
  },
  events: {
    [theme.breakpoints.up("md")]: {
      width: ({ eventId }) => (eventId ? "50%" : "100%"),
    },
  },
  eventDetails: {
    [theme.breakpoints.up("md")]: {
      width: ({ eventId }) => (eventId ? "50%" : "0%"),
    },
  },
  eventItem: {
    width: "100%",
    margin: "35px 0",
    transitionProperty: "width",
    transitionDuration: theme.transitions.duration.shortest,
    [theme.breakpoints.up("md")]: {
      width: "50%",
      padding: 0,
    },
  },
  eventsHead: {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    textTransform: "uppercase",
    color: theme.palette.primary.dark,
    textAlign: "center",
    padding: 20,
    fontSize: 18,
    fontWeight: 500,
  },
  eventsTableWrapper: {
    marginTop: 40,
    maxHeight: 350,
    [theme.breakpoints.up("md")]: {
      maxHeight: "100%",
    },
  },
  tableCol: {
    color: theme.palette.secondary.dark,
  },
  carouselArrowButtons: {
    maxHeight: 70,
  },
  headItem: {
    marginBottom: 15,
    marginTop: 15,
  },
  leaderSelect: {
    width: "33.333%",
    backgroundColor: ({ filtersLeader }) => (filtersLeader === "" ? undefined : theme.palette.primary.dark),
  },
  tagSelect: {
    width: "33.333%",
    backgroundColor: ({ filtersTag }) => (filtersTag === "" ? undefined : theme.palette.primary.dark),
  },
  joinedFilter: {
    width: "33.333%",
    minHeight: 42,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.light,
    color: "white",
    textTransform: "uppercase",
    textAlign: "center",
    alignSelf: "stretch",

    "& .MuiSvgIcon-root": {
      color: "white",
    },
  },
}));

/** */
const Events = () => {
  const t = useTranslate();
  const { format } = useDateUtils();
  const { sortedEvents, markAsWatched, setFilters, filters, leadersList, tagsList } = useEvents();
  const eventId = useExtractRouteParamInt("/events/:eventId", "eventId");
  const [eventIdForStyles, setEventIdForStyles] = useState(0); // in some cases just updating eventId doesn't update the following styles
  const classes = useStyles({ filtersLeader: filters.leader, filtersTag: filters.tag, eventId: eventIdForStyles });
  const carouselRef = useRef(null);
  const [isCarouselExists, setCarouselExists] = useState(false);
  const [carouselOpacity, setCarouselOpacity] = useState(0);
  const registeredUserEvents = sortedEvents[0] ? sortedEvents[0].events[0].registeredUserEvents : 0;
  const userEventsLimit = sortedEvents[0] ? sortedEvents[0].events[0].userEventsLimit : -1;

  useCompanyTitle("title.events");

  useEffect(() => {
    if (sortedEvents.length > 0 && isCarouselExists) {
      let goto = sortedEvents.length - 1;
      if (eventId) {
        goto = sortedEvents.findIndex((sEvents) => sEvents.events.findIndex((event) => event.id === eventId) !== -1);
      }
      carouselRef.current.slickGoTo(goto, true);
      setTimeout(() => {
        setCarouselOpacity(1);
      }, 100);
    }
  }, [sortedEvents, isCarouselExists, eventId]);

  // update eventId for styles
  useEffect(() => {
    setEventIdForStyles(eventId);
  }, [eventId]);

  /** @param {React.ReactNode} node */
  const onCarouselRefChanged = (node) => {
    if (node) {
      carouselRef.current = node;
      setCarouselExists(true);
    }
  };

  /**
   * @param {Number} oldIndex
   * @param {Number} newIndex
   */
  const onSlide = (oldIndex, newIndex) => {
    markAsWatched(newIndex);
  };

  return (
    <>
      <Box className={classes.headText}>
        <Typography variant="subtitle1" align="center">
          {t("Events.headNotice")}
        </Typography>
      </Box>
      <Box className={classes.headItem} width="100%">
        <StringSelectInput
          className={classes.leaderSelect}
          label={t("Events.leader")}
          value={filters.leader}
          onChange={({ target }) => setFilters({ ...filters, leader: target.value })}
          values={leadersList}
        />
        <StringSelectInput
          className={classes.tagSelect}
          label={t("Events.tag")}
          value={filters.tag}
          onChange={({ target }) => setFilters({ ...filters, tag: target.value })}
          values={tagsList}
        />
        <Box className={classes.joinedFilter}>
          <FormControlLabel
            value={filters.joined}
            control={
              <Checkbox color="primary" onChange={({ target }) => setFilters({ ...filters, joined: target.checked })} />
            }
            label={t("Events.joinedCheckbox")}
            labelPlacement="start"
          />
        </Box>
      </Box>
      <Box className={classes.headText}>
        <Typography variant="subtitle1" noWrap align="right">
          <b>{t("Events.joined")}&nbsp;&nbsp;</b>
          {registeredUserEvents}
          {userEventsLimit !== -1 && (
            <span>
              {" "}
              / <b>{userEventsLimit}</b>
            </span>
          )}
        </Typography>
      </Box>
      <Box className={classes.eventsWrapper}>
        <Box className={clsx(classes.eventItem, classes.events)}>
          {sortedEvents.length > 0 && (
            <Carousel
              ref={onCarouselRefChanged}
              sliderSettings={{ slidesToShow: 1, infinite: false }}
              style={{ opacity: carouselOpacity }}
              arrowButtonsClassName={classes.carouselArrowButtons}
              beforeChange={onSlide}
            >
              {sortedEvents.map((sEvent) => (
                <Box key={sEvent.events[0].date} data-tag="month's events">
                  <Typography className={classes.eventsHead}>
                    {format(new Date(sEvent.events[0].date), "LLLL")}
                  </Typography>
                  <TableContainer className={classes.eventsTableWrapper}>
                    <Table>
                      <TableBody>
                        {sEvent.events.map((event) => (
                          <EventRow event={event} key={event.id} />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              ))}
            </Carousel>
          )}
        </Box>
        <Box className={clsx(classes.eventItem, classes.eventDetails)}>{eventId && <Event />}</Box>
      </Box>
    </>
  );
};

export default Events;
