import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import ExerciseTitle from "./ExerciseTitle.jsx";
import LearnAudioPlayer from "./LearnAudioPlayer.jsx";
import ImageQuestion from "./ImageQuestion.jsx";
import UniversalVideoPlayer from "../../../../../SharedComponents/UniversalVideoPlayer.jsx";
import TextWithHTML from "../../../../../SharedComponents/TextWithHTML.jsx";

const useStyles = makeStyles((theme) => ({
  textQuestion: {
    fontSize: 19,
    fontWeight: 500,
    margin: "20px 30px 20px",
    whiteSpace: "pre-line",
    width: "100%",
    "& a": {
      color: theme.palette.text.secondary,
    },
  },
  videoPlayerWrapper: {
    width: 400,
    marginTop: 10,
    "&>div": {
      width: "100%",
    },
  },
  hr: {
    width: "100%",
    height: 2,
    minHeight: 2,
    marginTop: 40,
    backgroundColor: theme.palette.primary.main,
    border: "none",
  },
  audioWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "sticky",
    top: 48,
    width: "100%",
    padding: "20px 0",
  },
}));

/**
 * @param {Object} classes
 * @param {MediaQuestion} exerciseQuestion
 * @param {String} exerciseTitle
 * @param {String} defaultTitle
 * @param {Boolean} disableBottomSeparator
 * @returns {*}
 * @constructor
 */
const Question = ({ exerciseQuestion, exerciseTitle, defaultTitle, disableBottomSeparator }) => {
  const classes = useStyles();
  const question = exerciseQuestion;

  return (
    <>
      {!exerciseTitle && <ExerciseTitle>{defaultTitle}</ExerciseTitle>}
      {exerciseTitle && <ExerciseTitle>{exerciseTitle}</ExerciseTitle>}
      {question.text && <TextWithHTML text={question.text} className={classes.textQuestion} />}
      {question.audio && (
        <Box data-tag="audio question" className={classes.audioWrapper}>
          <LearnAudioPlayer url={question.audio.url} />
        </Box>
      )}
      {question.image && <ImageQuestion image={question.image} />}
      {question.video && (
        <div className={classes.videoPlayerWrapper}>
          <UniversalVideoPlayer url={question.video} height={225} />
        </div>
      )}
      {!disableBottomSeparator && <hr className={classes.hr} />}
    </>
  );
};

Question.propTypes = {
  defaultTitle: PropTypes.string.isRequired,
  disableBottomSeparator: PropTypes.bool,
  exerciseQuestion: PropTypes.object.isRequired,
  exerciseTitle: PropTypes.string,
};

export default Question;
