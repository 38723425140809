import React, { createContext, useContext, useEffect, useState } from "react";
import { loadObjectFromStorage, saveObjectToStorage } from "../../Utils/StorageUtils.js";
import { useUserPreferences } from "../Data/UserPreferencesProvider.jsx";
import { useHttp } from "../Common/HttpProvider.jsx";
import { WATCHED_NEWS } from "../Data/NewsProvider.jsx";
import { WATCHED_EVENTS } from "../Data/EventsProvider.jsx";
import { IS_COMMON_APP, companiesToCheckToken } from "../../constants.js";
import { ThemeContext } from "../../MyThemeProvider.jsx";
import { useLocale } from "../../Providers/i18n/I18nProvider.jsx";

const CHECKING_RESULT = "checking-result";

/**
 * @callback FnRegistration
 * @param {String} name
 * @param {String} phone
 * @param {String} aboutMe
 * @param {String} password
 * @param {Number} subjectId
 * @param {Number} offerId
 * @returns {Promise<void>}
 */

/**
 * @typedef {Object} AuthProviderContext
 * @property {?LoginResponse} user
 * @property {?Company} company
 * @property {?Company} company2
 * @property {?LoginResponse} subject
 * @property {?CheckEmailResponse} checkEmailResponse
 * @property {FnStringVoid} checkEmail
 * @property {FnStringNumberVoid} login
 * @property {FnRegistration} register
 * @property {FnAsyncObject} resetPassword
 * @property {Function} signOut
 * @property {Function} setSubject
 * @property {Function} setCompany2
 * @property {Function} setUser
 */

/**
 * @type {React.Context<AuthProviderContext>}
 */
const authContext = createContext({
  user: null,
  company: null,
  company2: null,
  subject: null,
  checkEmailResponse: null,
  /** @type {FnStringVoid} */ checkEmail: () => {},
  /** @type {FnStringNumberVoid} */ login: () => {},
  /** @type {FnRegistration} */ register: () => {},
  /** @type {FnAsyncObject} */ resetPassword: () => {},
  signOut: () => {},
  setSubject: () => {},
  setCompany2: () => {},
  setUser: () => {},
});

/**
 * @returns {AuthProviderContext}
 */
export const useAuth = () => useContext(authContext);

/**
 * @param {React.ReactNode} children
 */
const AuthProvider = ({ children }) => {
  const { clear } = useUserPreferences();
  const { signOutRef, post, authTokenRef, get } = useHttp();
  const { resetTheme, resetFont } = useContext(ThemeContext);
  const { setCompanyLocales } = useLocale();

  const [authEmail, setAuthEmail] = useState(/** @type {?String} */ null);
  const [company2, _setCompany2] = useState(/** @type {?Company} */ loadObjectFromStorage("company2"));

  const storageDataVersion = localStorage.getItem("version");

  if (!storageDataVersion) {
    localStorage.setItem("version", "1");
  }

  if (storageDataVersion && storageDataVersion != "1") {
    localStorage.removeItem("user");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("company");
    localStorage.removeItem("company2");
    localStorage.removeItem("color");
    localStorage.removeItem("subject");
    localStorage.removeItem("subjects");
    localStorage.removeItem(CHECKING_RESULT);
    localStorage.removeItem("current_branch");
    localStorage.removeItem("current_level");
    localStorage.setItem("version", "1");

    if (company2?.urn) {
      window.location.href = `/${company2.urn}`;
    } else {
      window.location.href = "/";
    }
  }

  const [user, setUser] = useState(/** @type LoginResponse */ loadObjectFromStorage("user"));
  const [company, setCompany] = useState(/** @type Company */ loadObjectFromStorage("company"));
  const [subject, setSubject] = useState(/** @type ?LoginResponse */ company ? loadObjectFromStorage("subject") : null);
  const [checkEmailResponse, setCheckEmailResponse] = useState(
    /** @type {?CheckEmailResponse} */ loadObjectFromStorage(CHECKING_RESULT),
  );

  // initialize saved in local storage auth token
  const subjectToken = subject && subject.token;
  authTokenRef.current = subjectToken || (user && user.token);

  // load saved authEmail
  useEffect(() => {
    setAuthEmail(checkEmailResponse && checkEmailResponse.userEmail);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // clear states
  useEffect(() => {
    if (!user) {
      localStorage.removeItem("userInfo");
    }
    if (!company) {
      localStorage.removeItem("subject");
      setSubject(null);
    }
  }, [user, company]);

  // Periodically check auth token
  useEffect(() => {
    if (!subjectToken) return;

    const intervalHandle = setInterval(() => {
      checkAuthTokenIfNeed();
    }, 10000);

    return () => clearInterval(intervalHandle);
  }, [subjectToken]);

  /** */
  const checkAuthTokenIfNeed = () => {
    if (company2 && !companiesToCheckToken.includes(company2.id)) return;

    get("userInfo/check", null, {}, true).then();
  };

  /**
   * @param {?Company} data
   */
  const setCompany2 = (data) => {
    _setCompany2(data);
    setCompany(data);
    if (!data) {
      localStorage.removeItem("company2");
    } else {
      saveObjectToStorage("company2", data);
      saveObjectToStorage("company", data);
    }
  };

  /** @type {FnStringVoid} */
  const checkEmail = async (email) => {
    /** @type {CheckEmailResponse} */
    const resp = await post("checkEmail", { email }, null, company2 && { "company-id": company2.id });
    if (resp) {
      saveObjectToStorage("company", resp.company);
      setAuthEmail(email);
      setCompany(resp.company);
      resp.userEmail = email;
      saveObjectToStorage(CHECKING_RESULT, resp);
      setCheckEmailResponse(resp);
      setCompanyLocales(resp.company.localeSet);
    }
  };

  /** @type {FnStringNumberVoid} */
  const login = async (password, subjectId, offerId) => {
    /** @type {LoginResponse} */
    const loginResponse = await post(
      "login",
      { email: authEmail, password, subjectId, offerId },
      null,
      company2 && { "company-id": company2.id },
    );
    if (loginResponse) {
      saveObjectToStorage("user", loginResponse);
      setUser(loginResponse);
      authTokenRef.current = loginResponse.token;
    }
  };

  /** @type {FnRegistration} */
  const register = async (name, phone, aboutMe, password, subjectId, offerId) => {
    /** @type {LoginResponse} */
    const resp = await post(
      "registration",
      {
        name,
        email: authEmail,
        phone: phone || "",
        aboutMe: aboutMe || null,
        password,
        subjectId,
        offerId,
      },
      null,
      company2 && { "company-id": company2.id },
    );
    if (resp) {
      saveObjectToStorage("user", resp);
      setUser(resp);
      authTokenRef.current = resp.token;
    }
  };

  /** @returns {Promise<?Object>} */
  const resetPassword = async () => {
    return post("passwordReset", { email: authEmail });
  };

  /** */
  const signOut = () => {
    clear();
    localStorage.removeItem("user");
    localStorage.removeItem("company");
    localStorage.removeItem(CHECKING_RESULT);
    localStorage.removeItem(WATCHED_NEWS);
    localStorage.removeItem(WATCHED_EVENTS);
    localStorage.removeItem("current_branch");
    localStorage.removeItem("current_level");
    setUser(null);
    setCompany(null);
    setAuthEmail(null);
    setCheckEmailResponse(null);
    if (IS_COMMON_APP) {
      setCompany2(null);
    }
    resetTheme();
    resetFont();
    authTokenRef.current = null;
  };

  signOutRef.current = signOut;

  /** @type AuthProviderContext */
  const value = {
    user,
    company,
    company2,
    subject,
    checkEmailResponse,
    checkEmail,
    login,
    register,
    resetPassword,
    signOut,
    setSubject,
    setCompany2,
    setUser,
  };
  return <authContext.Provider value={value}>{children}</authContext.Provider>;
};

export default AuthProvider;
