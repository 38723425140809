import PropTypes from "prop-types";
import React from "react";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey.js";
import { CardMedia } from "@material-ui/core";
import LinearProgressBar from "../../../SharedComponents/LinearProgressBar.jsx";
import Carousel from "../../../SharedComponents/Carousel.jsx";
import SmallLevelItem from "./SmallLevelItem.jsx";
import { useCourseProvider } from "../../../Providers/Data/CourseProvider.jsx";
import LockIcon from "../../../SharedComponents/LockIcon.jsx";
import { useBaseData } from "../../../Providers/Data/BaseDataProvider.jsx";
import { useAuth } from "../../../Providers/Auth/AuthProvider.jsx";

const useStyles = makeStyles((theme) => ({
  levelsCard: {
    position: "relative",
    width: 415,
    borderRadius: 10,
    backgroundColor: grey[50],
  },

  levelsCardSmall: {
    position: "relative",
    width: ({ contactInfo }) => (contactInfo ? "calc(100% - 100px)" : "100%"),
    borderRadius: 10,
    backgroundColor: grey[50],
  },

  levelsCardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  levelBigLogo: {
    height: 290,
    backgroundSize: "contain",
    margin: 16,
  },
  levelName: {
    margin: "10px 0 0 0",
  },
  points: {
    fontSize: 18,
  },
  totalPoints: {
    fontWeight: 900,
  },
  currentLevelProgress: {
    flexGrow: 1,
    margin: "3px 0 0 21px",
  },
  levelsSmallCarousel: {
    width: "100%",
    margin: 0,
  },
  marginTop30: {
    marginTop: 30,
  },
  lockIcon: {
    top: 20,
    right: 20,
    width: 70,
  },
}));

/**
 * @param {Level} currentLevel
 * @return {*}
 * @constructor
 */
const Progress = ({ currentLevel }) => {
  const classes = useStyles();

  return (
    <Box display="flex" width="100%" margin="31px 0 0 0">
      <Typography className={classes.points} color="primary">
        {currentLevel.havePoints}&nbsp;/&nbsp;
      </Typography>
      <Typography className={clsx(classes.totalPoints, classes.points)} color="primary">
        {currentLevel.points}
      </Typography>
      <LinearProgressBar
        className={classes.currentLevelProgress}
        variant="determinate"
        value={(currentLevel.havePoints / currentLevel.points) * 100}
      />
    </Box>
  );
};

Progress.propTypes = {
  currentLevel: PropTypes.object,
};

/**
 * @param {Number} currentLevelIndex
 * @param {Number} slidesToShow
 * @param {LevelsAndReward} levelsAndReward
 * @param {Function} changeLevel
 * @param {Boolean} tinyMode
 * @return {*}
 * @constructor
 */
const Levels = ({ currentLevelIndex, slidesToShow, levelsAndReward, changeLevel, tinyMode }) => {
  const classes = useStyles();

  const initialSlideIndex = levelsAndReward.levels.findIndex((element) => element.havePoints < element.points);

  return (
    <Carousel
      className={clsx(classes.levelsSmallCarousel, { [classes.marginTop30]: !tinyMode })}
      sliderSettings={{ slidesToShow, initialSlide: initialSlideIndex, slidesToScroll: slidesToShow }}
    >
      {levelsAndReward.levels.map((level, i) => (
        <SmallLevelItem
          key={level.id}
          level={level}
          onClick={() => changeLevel(levelsAndReward.levels[i])}
          active={currentLevelIndex === i}
          tinyMode={tinyMode}
        />
      ))}
    </Carousel>
  );
};

Levels.propTypes = {
  currentLevelIndex: PropTypes.number,
  levelsAndReward: PropTypes.object,
  changeLevel: PropTypes.func,
  slidesToShow: PropTypes.number,
  tinyMode: PropTypes.bool,
};

Levels.defaultProps = {
  tinyMode: false,
};

/** @param {Boolean} tinyMode
 * @returns {null|*}
 * @constructor
 */
const LevelsCard = ({ tinyMode }) => {
  const { company2 } = useAuth();
  const contactInfo = company2.communicationLink;
  const classes = useStyles({ contactInfo });
  const { levelsAndReward, currentLevel, changeLevel } = useCourseProvider();
  const { userInfo } = useBaseData();

  if (!currentLevel || !levelsAndReward) {
    return null;
  }

  const currentLevelIndex = levelsAndReward.levels.findIndex((level) => level.id === currentLevel.id);
  const slidesToShow = levelsAndReward.levels.length > 3 ? 3 : levelsAndReward.levels.length;
  const tinySlidesToShow = levelsAndReward.levels.length > 6 ? 6 : levelsAndReward.levels.length;
  const tinySlidesToShowContact = levelsAndReward.levels.length > 5 ? 5 : levelsAndReward.levels.length;

  return (
    <Card className={tinyMode ? classes.levelsCardSmall : classes.levelsCard} elevation={3}>
      {!tinyMode && (
        <CardMedia
          className={classes.levelBigLogo}
          image={(currentLevel.imageUrl || currentLevel.image?.url)?.replaceAll("\\", "/")}
        />
      )}
      <CardContent className={classes.levelsCardContent}>
        {!tinyMode && (!currentLevel.open || !(userInfo.isActive || userInfo.isDemoMode)) && (
          <LockIcon className={classes.lockIcon} />
        )}
        {!tinyMode && (
          <Typography className={classes.levelName} variant="h6">
            {currentLevel.name}
          </Typography>
        )}
        {!tinyMode ? (
          <Progress currentLevel={currentLevel} />
        ) : (
          <Levels
            currentLevelIndex={currentLevelIndex}
            levelsAndReward={levelsAndReward}
            changeLevel={changeLevel}
            slidesToShow={contactInfo ? tinySlidesToShowContact : tinySlidesToShow}
            tinyMode={tinyMode}
          />
        )}
        {!tinyMode ? (
          <Levels
            currentLevelIndex={currentLevelIndex}
            levelsAndReward={levelsAndReward}
            changeLevel={changeLevel}
            slidesToShow={slidesToShow}
            tinyMode={tinyMode}
          />
        ) : (
          <Progress currentLevel={currentLevel} />
        )}
      </CardContent>
    </Card>
  );
};

LevelsCard.propTypes = {
  tinyMode: PropTypes.bool,
};

LevelsCard.defaultProps = {
  tinyMode: false,
};

export default LevelsCard;
