import PropTypes from "prop-types";
import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useTranslate } from "react-polyglot";
import { makeStyles, withStyles } from "@material-ui/core";
import { Route, Switch } from "react-router-dom";
import { grey } from "@material-ui/core/colors";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
import { IS_APP_BEHAPPY, IS_APP_STUDYLESS } from "../../constants.js";
import useTabsRouter from "../../hooks/useTabsRouter.js";
import Learn from "./Learning/Learn.jsx";
import CourseStartPage from "./Course/CourseStartPage.jsx";
import Dictionary from "./Dictionary/Dictionary.jsx";
import Events from "./Events/Events.jsx";
import News from "./News/News.jsx";
import useDesktopWidthMatch from "../../hooks/useDesktopWidthMatch.js";
import CourseProvider from "../../Providers/Data/CourseProvider.jsx";
import DictionaryProvider from "../../Providers/Data/DictionaryProvider.jsx";
import Profile from "../Profile/Profile.jsx";
import Missions from "../Missions/Missions.jsx";
import Leaderboard from "../Leaderboard/Leaderboard.jsx";
import MissionsProvider from "../../Providers/Data/MissionsProvider.jsx";
import FlexboxVertical from "../../SharedComponents/FlexboxVertical.jsx";
import ProtectedRoute from "../../Providers/Data/ProtectedRoute.jsx";
import { useBaseData } from "../../Providers/Data/BaseDataProvider.jsx";
import { useEvents } from "../../Providers/Data/EventsProvider.jsx";
import { useNews } from "../../Providers/Data/NewsProvider.jsx";
import Homeworks from "./Homeworks/Homeworks.jsx";
import HomeworksProvider from "../../Providers/Data/HomeworksProvider.jsx";
import ToDoHomework from "./Homeworks/ToDoHomework.jsx";
import Orders from "../Orders/Orders.jsx";
import EventsStudyless from "./Events/EventsStudyless.jsx";

const useStyles = makeStyles({
  tabsContainer: {
    backgroundColor: grey[100],
    "& .Mui-selected": {
      fontWeight: 600,
    },
    "& .MuiTab-root": {
      fontSize: 14,
    },
  },
  fixedWidth: {
    width: ({ isDesktop }) => (isDesktop ? 1300 : 847),
    margin: "0 auto",
  },
});

/**
 * @returns {*}
 * @constructor
 */
const HomeTabs = () => {
  const isDesktop = useDesktopWidthMatch();
  const classes = useStyles({ isDesktop: isDesktop });
  const t = useTranslate();
  const { userInfo } = useBaseData();
  const { unwatchedCount: unwatchedEventsCount } = useEvents();
  const { unwatchedCount: unwatchedNewsCount } = useNews();
  const { tabIndex, onTabChanged } = useTabsRouter([
    "/",
    "/dictionary",
    "/homeworks",
    "/events",
    "/news",
    "/missions",
    "/profile",
    "/orders",
    "/leaderboard",
  ]);

  return (
    <FlexboxVertical width="100%">
      <Box className={classes.tabsContainer}>
        <Tabs
          className={classes.fixedWidth}
          variant="fullWidth"
          value={tabIndex}
          indicatorColor="primary"
          onChange={onTabChanged}
        >
          <Tab label={t("course")} />
          <Tab label={t("dictionary")} style={{ display: userInfo.subject.dictionaryEnabled ? undefined : "none" }} />
          <Tab label={t("homeworks.tabName")} />
          <BadgedTab
            label={t("events")}
            badgeContent={unwatchedEventsCount}
            style={{ display: userInfo?.subject?.eventSettings?.isEventsListAvailable ? undefined : "none" }}
          />
          <BadgedTab label={t("news")} badgeContent={unwatchedNewsCount} />
          <Tab label={t("missions")} style={{ display: userInfo.subject.missionsEnabled ? undefined : "none" }} />
          <Tab style={{ display: "none" }} /> {/* Profile */}
          <Tab style={{ display: "none" }} /> {/* Orders */}
          <Tab style={{ display: "none" }} /> {/* Leaderboard */}
        </Tabs>
      </Box>
      <Box className={classes.fixedWidth}>
        <Switch>
          <Route exact path="/">
            <CourseProvider>
              <CourseStartPage />
            </CourseProvider>
          </Route>
          <ProtectedRoute
            path={["/learn/:deckId", "/learn/:deckId/:wordId"]}
            condition={userInfo.isActive || userInfo.isDemoMode}
            forbiddenRedirect="/"
          >
            <CourseProvider>
              <Learn />
            </CourseProvider>
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/dictionary"
            condition={userInfo.subject.dictionaryEnabled && (userInfo.isActive || userInfo.isDemoMode)}
            forbiddenRedirect="/"
          >
            <DictionaryProvider>
              <Dictionary />
            </DictionaryProvider>
          </ProtectedRoute>
          <ProtectedRoute path="/homeworks" condition={userInfo.isActive || userInfo.isDemoMode} forbiddenRedirect="/">
            <HomeworksProvider>
              <Route exact path="/homeworks">
                <Homeworks />
              </Route>
              <Route path={["/homeworks/todo", "/homeworks/finished"]}>
                <ToDoHomework />
              </Route>
            </HomeworksProvider>
          </ProtectedRoute>
          <ProtectedRoute
            path="/events"
            condition={
              (userInfo.isActive || userInfo.isDemoMode) && userInfo?.subject?.eventSettings?.isEventsListAvailable
            }
            forbiddenRedirect="/"
          >
            {IS_APP_STUDYLESS ? <EventsStudyless /> : <Events />}
          </ProtectedRoute>
          <ProtectedRoute path="/news" condition={userInfo.isActive || userInfo.isDemoMode} forbiddenRedirect="/">
            <News />
          </ProtectedRoute>
          <Route path="/profile">
            <Profile />
          </Route>
          <Route path="/orders">
            <Orders />
          </Route>
          <ProtectedRoute
            path="/missions"
            condition={userInfo.subject.missionsEnabled && (userInfo.isActive || userInfo.isDemoMode)}
            forbiddenRedirect="/"
          >
            <MissionsProvider>
              <Missions />
            </MissionsProvider>
          </ProtectedRoute>
          <ProtectedRoute path="/leaderboard" condition={!IS_APP_STUDYLESS && !IS_APP_BEHAPPY} forbiddenRedirect="/">
            <Leaderboard />
          </ProtectedRoute>
        </Switch>
      </Box>
    </FlexboxVertical>
  );
};

/** */
const BadgedTab = ({ badgeContent, style, ...rest }) => {
  return (
    <StyledBadge badgeContent={badgeContent} style={style} color="error">
      <Tab {...rest} />
    </StyledBadge>
  );
};

BadgedTab.propTypes = {
  badgeContent: PropTypes.any.isRequired,
  style: PropTypes.object,
};

const StyledBadge = withStyles((theme) => ({
  root: {
    flexBasis: 0,
    flexGrow: 1,
  },
  badge: {
    left: "calc(50% + 50px)",
    right: "unset",
    top: 18,
    //border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);

export default HomeTabs;
