import React, { createContext, useContext, useEffect, useState } from "react";
import _ from "lodash";
import * as queryString from "query-string";
import { useAuth } from "../Auth/AuthProvider.jsx";
import { useUserPreferences } from "./UserPreferencesProvider.jsx";
import { loadObjectFromStorage, saveObjectToStorage } from "../../Utils/StorageUtils.js";
import { useHttp } from "../Common/HttpProvider.jsx";
import { useLocale } from "../../Providers/i18n/I18nProvider.jsx";
import useEffectAsync from "../../hooks/useEffectAsync.js";
import { CONTENT_PAYMENT_TYPES, IS_COMMON_APP, IS_APP_STUDYLESS } from "../../constants.js";
import { ThemeContext } from "../../MyThemeProvider.jsx";

export const PRESELECTED_SUBJECT = "preselected subject";
export const PRESELECTED_OFFER = "preselected offer";

/**
 * @callback SetPreselectedSubject
 * @param {?Number} subjectId
 * @returns {*}
 */

/**
 * @callback SetPreselectedOffer
 * @param {?Number} offerId
 * @returns {*}
 */

/**
 * @callback UpdateUserInfo
 * @param {UserDataUpdate} data
 * @returns {Promise<Boolean>}
 */

/**
 * @callback GetResources
 * @param {string[]} resources
 * @param {boolean} [silent]
 * @return {Promise<void>}
 */

/**
 * @callback GetAvailableSubjects
 * @return {Promise<void>}
 */

/**
 * @typedef {Object} LeaderboardFilters
 * @property {String} text
 */

/**
 * @typedef {Object} DataProviderContext
 * @property {?LoginResponse} subject
 * @property {?Company} company
 * @property {?UserInfo} userInfo
 * @property {?Leader[]} leaderboard
 * @property {?UserInfo} leaderInfo
 * @property {?LeaderExtended[]} filteredLeaderboard
 * @property {?LevelsAndReward} levelsAndReward
 * @property {?Counts} counts
 * @property {?CompanySubject[]} availableSubjects
 * @property {?Branch[]} branches
 * @property {?TheNews[]} news
 * @property {SkillzrunEvent[]} events
 * @property {?Number} preselectedSubject
 * @property {?Number} preselectedOffer
 * @property {?Boolean} schoolNotFound
 * @property {?object} payHtml
 * @property {?Offer} offer
 * @property {LeaderboardFilters} leaderboardFilters
 *
 * @property {GetResources} getResources
 * @property {getAvailableSubjectsForCompany} getAvailableSubjectsForCompany
 * @property {function} getAvailableSubjects
 * @property {FnAsyncNumberBoolean} chooseSubject
 * @property {function} refreshUserInfo
 * @property {UpdateUserInfo} updateUserInfo
 * @property {FnAsyncVoid} requestLeaderboard
 * @property {FnAsyncVoid} requestLevels
 * @property {FnAsyncVoid} requestCounts
 * @property {function} setBranches
 * @property {function} setNews
 * @property {function} setEvents
 * @property {SetPreselectedSubject} setPreselectedSubject
 * @property {SetPreselectedOffer} setPreselectedOffer
 * @property {Function} setSchoolName
 * @property {FnAsyncNumberStringVoid} requestPaymentHtml
 * @property {FnAsyncVoid} setFirebaseToken
 * @property {FnAsyncStringNumberVoid} requestOffer
 * @property {FnStringVoid} enableSubscribe
 * @property {FnStringVoid} stopSubscribe
 * @property {function} clearPayHtml
 * @property {FnObjectVoid} setLeaderboardFilters
 * @property {function} setLeaderInfo
 * @property {FnAsyncStringVoid} requestLeaderInfo
 */

/** @type {React.Context<DataProviderContext>} */
const dataContext = createContext({
  subject: null,
  company: null,
  userInfo: null,
  leaderboard: [],
  leaderInfo: null,
  filteredLeaderboard: [],
  levelsAndReward: null,
  counts: null,
  availableSubjects: null,
  branches: null,
  news: null,
  events: [],
  preselectedSubject: null,
  preselectedOffer: null,
  schoolNotFound: null,
  payHtml: null,
  userOrders: null,
  offer: null,
  leaderboardFilters: {},
  /** @type {GetAvailableSubjects} */ getAvailableSubjects: () => {},
  /** @type {GetResources} */ getResources: async () => {},
  /** @type {getAvailableSubjectsForCompany} */ getAvailableSubjectsForCompany: () => {},
  /** @type FnAsyncNumberBoolean */ chooseSubject: () => {},
  /** @type {function} */ refreshUserInfo: () => {},
  /** @type {UpdateUserInfo} */ updateUserInfo: () => {},
  /** @type FnAsyncVoid */ requestLeaderboard: () => {},
  /** @type FnAsyncStringVoid */ requestLeaderInfo: () => {},
  /** @type FnAsyncVoid */ requestLevels: () => {},
  /** @type FnAsyncVoid */ requestCounts: () => {},
  /** @type function */ setBranches: () => {},
  /** @type function */ setNews: () => {},
  /** @type function */ setEvents: () => {},
  /** @type {SetPreselectedSubject} */ setPreselectedSubject: () => {},
  /** @type {SetPreselectedOffer} */ setPreselectedOffer: () => {},
  /** @type {Function} */ setSchoolName: () => {},
  /** @type {FnAsyncNumberStringVoid} */ requestPaymentHtml: () => {},
  /** @type FnAsyncVoid */ setFirebaseToken: () => {},
  /** @type {FnAsyncStringNumberVoid} */ requestOffer: () => {},
  /** @type FnStringVoid */ enableSubscribe: () => {},
  /** @type FnStringVoid */ stopSubscribe: () => {},
  /** @type function */ clearPayHtml: () => {},
  /** @type {FnObjectVoid} */ setLeaderboardFilters: () => {},
});

/**
 * @returns {DataProviderContext}
 */
export const useBaseData = () => useContext(dataContext);

/**
 * @param {React.ReactNode} children
 * @returns {*}
 * @constructor
 */
const BaseDataProvider = ({ children }) => {
  /** @type {String} */
  const { get, post, put, patch, authTokenRef } = useHttp();
  const { user, company, company2, subject, setSubject, setCompany2, setUser } = useAuth();
  const { setCompanyLocales } = useLocale();
  const { setThemeColor, setFontFamily, resetFont } = useContext(ThemeContext);
  const [userInfo, setUserInfo] = useState(/** @type ?UserInfo */ user ? loadObjectFromStorage("userInfo") : null);
  const [leaderboard, setLeaderboard] = useState(/** @type ?Leader[] */ []);
  const [leaderInfo, setLeaderInfo] = useState(/** @type ?UserInfo */ null);
  const [filteredLeaderboard, setFilteredLeaderboard] = useState(/** @type ?LeaderExtended[] */ []);
  const [levelsAndReward, setLevelsAndReward] = useState(/** @type ?LevelsAndReward */ null);
  const [counts, setCounts] = useState(/** @type ?Counts */ null);
  const [availableSubjects, setAvailableSubjects] = useState(/** @type {?CompanySubject[]} */ null);
  const [branches, setBranches] = useState(/** @type ?Branch[] */ null);
  const [news, setNews] = useState(/** @type {?TheNews[]} */ null);
  const [events, setEvents] = useState(/** @type {SkillzrunEvent[]} */ []);
  const [schoolName, setSchoolName] = useState(/** @type {?string} */ null);
  const [preselectedSubject, _setPreselectedSubject] = useState(
    /** @type {?Number} */ loadObjectFromStorage(PRESELECTED_SUBJECT),
  );
  const [preselectedOffer, _setPreselectedOffer] = useState(
    /** @type {?Number} */ loadObjectFromStorage(PRESELECTED_OFFER),
  );
  const [schoolNotFound, setSchoolNotFound] = useState(null);
  const [userOrders, setUserOrders] = useState(null);
  const [offer, setOffer] = useState(/** @type {?Offer} */ null);
  const [payHtml, setPayHtml] = useState(/** @type {?object} */ null);
  const [leaderboardFilters, setLeaderboardFilters] = useState({ text: "" });
  const { setLang } = useUserPreferences();

  const companyMainColor = company2?.colors?.main;
  const companyFont = company2?.font || company?.font;

  // Set theme color
  useEffect(() => {
    if (companyMainColor) {
      setThemeColor(companyMainColor);
    }
  }, [companyMainColor]);

  // Set company font
  useEffect(() => {
    if (companyFont) {
      setFontFamily(companyFont);
    } else {
      resetFont();
    }
  }, [companyFont]);

  // Load initial data for Home screen
  useEffect(() => {
    if (subject) {
      setLevelsAndReward(null);
      getResources(["company", "counts", "branchesTree", "events", "levels", "userInfo", "news", "leaders"]).then();
    }
  }, [subject]);

  // load leaderboard on start and on subject change
  // useEffect(() => {
  //   requestLeaderboard().then();
  //   //eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [subject]);

  // ONLY! update user info at start
  // useEffectAsync(async () => {
  //   if (userInfo) {
  //     await refreshUserInfo();
  //   }
  // }, []);

  // load company info if the app placed on custom domain
  useEffect(() => {
    if (!IS_COMMON_APP && !company2) {
      fetchSchoolData(window.location.host).then();
    }
  }, []);

  // clear states
  useEffect(() => {
    if (!user) {
      setUserInfo(null);
    }
    if (!company) {
      setLeaderboard([]);
      setLevelsAndReward(null);
      setCounts(null);
      setAvailableSubjects(null);
      if (IS_COMMON_APP) {
        setPreselectedSubject(null);
      }
      setPreselectedOffer(null);
      setSchoolNotFound(null);
    }
  }, [user, company, schoolName]);

  // if user on personal school page then request public subjects for this school
  useEffectAsync(async () => {
    if (schoolName) {
      fetchSchoolData(schoolName).then();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolName]);

  //filter leaderboard by name
  useEffect(() => {
    const extendedLeaders = leaderboard.map((leader, i) => ({ ...leader, leaderboardPlace: i + 1 }));

    let filteredList = extendedLeaders.filter((leader) => {
      return leaderboardFilters.text !== ""
        ? leader.name.toUpperCase().contains(leaderboardFilters.text.toUpperCase())
        : true;
    });

    setFilteredLeaderboard(filteredList);
  }, [leaderboard, leaderboardFilters]);

  /**
   * @param {string[]} resources
   * @param {boolean} [silent] если true, то блокирующий весь экран кружок загрузки не показывается
   * @return {Promise<void>}
   */
  const getResources = async (resources, silent = false) => {
    if (IS_APP_STUDYLESS || !subject.showLeaderboard) {
      _.remove(resources, (res) => res === "leaders");
    }
    const query = queryString.stringify({ resource: resources });
    /** @type {StartInfo} */
    const resp = await get(`startInfo?${query}`, null, null, silent);
    if (resp) {
      if (resp.company) {
        saveObjectToStorage("subjects", resp.company.subjects);
        setAvailableSubjects(resp.company.subjects);
        setCompany2(resp.company.company);
        setCompanyLocales(resp.company.company.localeSet);
      }
      if (resp.branchesTree) {
        setBranches(resp.branchesTree);
      }
      if (resp.levels) {
        setLevelsAndReward(resp.levels);
      }
      if (resp.counts) {
        setCounts(resp.counts);
      }
      if (resp.leaders) {
        setLeaderboard(resp.leaders);
      }
      if (resp.userInfo) {
        setUserInfo(resp.userInfo);
        saveObjectToStorage("userInfo", resp.userInfo);
      }
      if (resp.news) {
        setNews(resp.news);
      }
      if (resp.events) {
        setEvents(resp.events);
      }
    }
  };

  /**
   * @param {string} schoolName
   * @returns {Promise<void>}
   */
  const fetchSchoolData = async (schoolName) => {
    /** @type {{company: Company, subjects: ShortSubject[]}} */
    const data = await get(`company/${schoolName}`);
    if (data) {
      data.subjects.forEach((subj) => (subj.groups = [{ id: -1 }]));
      setSchoolNotFound(false);
      setAvailableSubjects(data.subjects);
      setCompany2(data.company);
      setLang(data.company.mailLocale);
      setCompanyLocales(data.company.localeSet);
    } else {
      setSchoolNotFound(true);
    }
  };

  /**
   * @returns {Promise<void>}
   */
  const refreshUserInfo = async () => {
    /** @type {UserInfo} */
    const userInfoResp = await get("userInfo");
    if (userInfoResp) {
      setUserInfo(userInfoResp);
      saveObjectToStorage("userInfo", userInfoResp);
    }
  };

  /**
   * @type FnAsyncVoid
   * @param {string} firebaseToken
   */
  const setFirebaseToken = async (firebaseToken) => {
    await patch(`firebaseTokens`, { newFirebaseToken: firebaseToken, oldFirebaseToken: firebaseToken });
  };

  /** @callback getAvailableSubjectsForCompany
   * @param {string} companyName
   * @returns {Promise<*>}
   */

  /** @type {getAvailableSubjectsForCompany}
   * @param {string} companyName
   */
  const getAvailableSubjectsForCompany = async (companyName) => {
    if (companyName !== schoolName) {
      setSchoolName(companyName);
    } else if (companyName) {
      fetchSchoolData(companyName).then();
    }
  };

  /** */
  const getAvailableSubjects = async () => {
    const data = await get("company");
    if (data) {
      saveObjectToStorage("subjects", data.subjects);
      setAvailableSubjects(data.subjects);
      setCompany2(data.company);
    }

    return data.subjects;
  };

  /** @type FnAsyncNumberBoolean */
  const chooseSubject = async (subjectId) => {
    /** @type {LoginResponse} */
    const resp = await post("changeSubject", { subjectId });
    if (resp) {
      saveObjectToStorage("subject", resp);
      const userInfoResp = await get("userInfo", null, { authorization: resp.token });
      let userInfo_;
      if (userInfoResp) {
        userInfo_ = userInfoResp;
        saveObjectToStorage("userInfo", userInfo_);
      } else {
        return false;
      }
      authTokenRef.current = resp.token;
      setSubject(resp);
      setUserInfo(userInfo_);
      setSchoolName(null);
      setPreselectedSubject(null);
      return true;
    }
    return false;
  };

  /** @type FnAsyncStringNumberVoid */
  const requestOffer = async (urn, offerId) => {
    const resp = await get(`${urn}/offers/${offerId}`, null, company2 && { "company-id": company2.id });
    if (resp) {
      setOffer(resp);
    }
  };

  /** @type FnAsyncNumberBoolean */
  const discardOffer = async (userOfferId) => {
    const resp = await get(`userOffers/cancel/${userOfferId}`);
    if (resp) {
      if (resp.status === "canceled") {
        await refreshUserInfo();
        return true;
      }
    }
    return false;
  };

  /** @type FnAsyncNumberBoolean */
  const stopSubscribe = async (subscribeId) => {
    const resp = await get(`userSubscribes/stop/${subscribeId}`);
    if (resp && resp === "disabled") {
      await refreshUserInfo();
      return true;
    }
    return false;
  };

  /** @type FnAsyncNumberBoolean */
  const enableSubscribe = async (subscribeId) => {
    const resp = await get(`userSubscribes/enable/${subscribeId}`);
    if (resp && resp === "active") {
      await refreshUserInfo();
      return true;
    }
    return false;
  };

  /** @type {UpdateUserInfo} */
  const updateUserInfo = async (data) => {
    /** @type {LoginResponse} */
    const resp = await put("userInfoEdit", data, null, { "company-id": company.id });
    if (resp) {
      saveObjectToStorage("user", resp);
      setUser(resp);
      authTokenRef.current = resp.token;
      const newSubject = { ...subject, token: resp.token };
      setSubject(newSubject);
      saveObjectToStorage("subject", newSubject);
      const userInfoResp = await get("userInfo");
      if (userInfoResp) {
        saveObjectToStorage("userInfo", userInfoResp);
        setUserInfo(userInfoResp);
      }
      return true;
    }
    return false;
  };

  /** @type FnAsyncVoid */
  const requestLeaderboard = async () => {
    if (!subject || !subject.token) {
      return;
    }
    const resp = await get("leaders");
    if (resp) {
      setLeaderboard(resp);
    }
  };

  /** @type FnAsyncStringVoid */
  const requestLeaderInfo = async (userId) => {
    const resp = await get(`leaders/${userId}`);
    if (resp) {
      setLeaderInfo(resp);
    }
  };

  /** @type FnAsyncVoid */
  const requestLevels = async () => {
    const resp = await get("levels");
    if (resp) {
      setLevelsAndReward(resp);
    }
  };

  /** @type {FnAsyncVoid} */
  const requestCounts = async () => {
    const resp = await get("counts");
    if (resp) {
      setCounts(resp);
    }
  };

  /** @type {FnAsyncNumberStringVoid} */
  const requestPaymentHtml = async (id, type) => {
    /** @type {?PaymentWebPageData} */
    const resp = await get(`pay/${type}/${id}`);

    if (resp) {
      switch (resp.contentType) {
        case CONTENT_PAYMENT_TYPES.url:
          setPayHtml({ data: resp.data, type: resp.type });
          break;
        case CONTENT_PAYMENT_TYPES.html:
          setPayHtml({ data: resp.data.base64Src(), type: resp.type });
          break;
      }
    }
  };

  /** */
  const clearPayHtml = () => {
    setPayHtml(null);
  };

  /** @type {FnAsyncNumberVoid} */
  const requestUserOrders = async () => {
    const resp = await get("userOrders");

    if (resp) {
      setUserOrders(resp);
    }
  };

  /** */
  const setPreselectedSubject = (value) => {
    _setPreselectedSubject(value);
    if (!value) {
      localStorage.removeItem(PRESELECTED_SUBJECT);
    } else {
      saveObjectToStorage(PRESELECTED_SUBJECT, value);
    }
  };

  /** */
  const setPreselectedOffer = (value) => {
    _setPreselectedOffer(value);
    if (!value) {
      localStorage.removeItem(PRESELECTED_OFFER);
    } else {
      saveObjectToStorage(PRESELECTED_OFFER, value);
    }
  };

  /** @type DataProviderContext */
  const value = {
    // data:
    subject,
    company,
    userInfo,
    leaderboard,
    leaderInfo,
    levelsAndReward,
    counts,
    availableSubjects,
    branches,
    news,
    events,
    preselectedSubject,
    preselectedOffer,
    schoolNotFound,
    payHtml,
    userOrders,
    offer,
    leaderboardFilters,
    filteredLeaderboard,
    // methods:
    getResources,
    getAvailableSubjectsForCompany,
    getAvailableSubjects,
    chooseSubject,
    refreshUserInfo,
    updateUserInfo,
    requestLeaderboard,
    requestLeaderInfo,
    requestLevels,
    requestCounts,
    setBranches,
    setNews,
    setEvents,
    setPreselectedSubject,
    setPreselectedOffer,
    setSchoolName,
    requestPaymentHtml,
    discardOffer,
    stopSubscribe,
    enableSubscribe,
    requestUserOrders,
    setFirebaseToken,
    requestOffer,
    clearPayHtml,
    setLeaderboardFilters,
    setLeaderInfo,
  };
  return <dataContext.Provider value={value}>{children}</dataContext.Provider>;
};

export default BaseDataProvider;
