import React, { useEffect, useRef } from "react";
import { useCourseProvider } from "../../../Providers/Data/CourseProvider.jsx";
import { throttle } from "../../../Utils/misc.js";

// Документация по SCORM API: https://scorm.com/scorm-explained/technical-scorm/run-time/run-time-reference/

/**
 * @param {string} message
 */
function scorm12log(message) {
  console.log(`SCORM 1.2: ${message}`);
}

/**
 * @param {string} message
 */
function scorm2004log(message) {
  console.log(`SCORM 2004: ${message}`);
}

/**
 * @param {?Object} serverScormProgress
 * @param {FnAsyncObjectVoid} updateScormProgress
 */
const useScormApi = (serverScormProgress, updateScormProgress) => {
  const dataRef = useRef(null); // стейты не работают с вызовами функций из iframe, поэтому useRef

  /** */
  const commitProgress = () => {
    console.log("💾 сохранение прогресса");
    updateScormProgress(dataRef.current).then();
  };

  const throttleCommit = throttle(commitProgress, 2000);

  /** */
  const init = () => {
    // при инициализации подтянуть прогресс с сервера
    dataRef.current = serverScormProgress;
    return "true";
  };

  /** */
  const finish = () => {
    console.log("📤 LMSFinish");
    // Сохраняем прогресс при завершении
    throttleCommit();
    return "true";
  };

  /**
   * @param {string} key
   * @returns {string}
   */
  const getValue = (key) => {
    const retVal = dataRef.current[key] || "";
    console.log(`📥 LMSGetValue: ${key} = ${retVal}`);
    return retVal;
  };

  /**
   * @param {string} key
   * @param {string} value
   * @return {string}
   */
  const setValue = (key, value) => {
    console.log(`📤 LMSSetValue: ${key} = ${value}`);
    dataRef.current[key] = value;
    return "true";
  };

  /** */
  const commit = () => {
    console.log("💾 LMSCommit", { ...dataRef.current });
    throttleCommit();
    return "true";
  };

  /** */
  const getLastError = () => {
    console.log("LMSGetLastError");
    return "0"; // Всегда "0" если ошибок нет
  };

  /**
   * @param {string} errorCode
   * @return {string}
   */
  const getErrorString = (errorCode) => {
    console.log(`LMSGetErrorString: errorCode = ${errorCode}`);
    return "No error"; // Простая заглушка
  };

  /**
   * @param {string} errorCode
   * @return {string}
   */
  const getDiagnostic = (errorCode) => {
    console.log(`LMSGetDiagnostic: errorCode = ${errorCode}`);
    return "No diagnostic info"; // Тоже заглушка
  };

  // Инизиализация SCORM API
  useEffect(() => {
    if (serverScormProgress === null) {
      return;
    }

    // SCORM v1.2 api:
    window.API = {
      LMSInitialize: () => {
        scorm12log("📥 LMSInitialize");
        return init();
      },
      LMSFinish: finish,
      LMSGetValue: getValue,
      LMSSetValue: setValue,
      LMSCommit: commit,
      LMSGetLastError: getLastError,
      LMSGetErrorString: getErrorString,
      LMSGetDiagnostic: getDiagnostic,
    };

    // SCORM v2004 api:
    window.API_1484_11 = {
      Initialize: () => {
        scorm2004log("📥 LMSInitialize");
        return init();
      },
      Terminate: finish,
      GetValue: getValue,
      SetValue: setValue,
      Commit: commit,
      GetLastError: getLastError,
      GetErrorString: getErrorString,
      GetDiagnostic: getDiagnostic,
    };

    console.log("✔ SCORM API instantiated");

    return () => {
      // Чистим API при уходе со страницы
      delete window.API;
      delete window.API_1484_11;
      console.log("❌ SCORM API removed");
    };
  }, [serverScormProgress]);
};

/**
 * @returns {*}
 * @constructor
 */
const ScormDeckContent = () => {
  const { currentDeck, scormProgress, updateScormProgress } = useCourseProvider();
  useScormApi(scormProgress, updateScormProgress);

  if (scormProgress === null) {
    return null;
  }

  return (
    <div style={{ width: "100%" }}>
      <iframe src={`/${currentDeck.scormEntryPoint}`} style={{ width: "100%", height: "calc(100vh - 100px)" }} />
    </div>
  );
};

export default ScormDeckContent;
