import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { useTranslate } from "react-polyglot";
import { sanitizedData, replaceUrlsOutsideTags, parseTextWithBbcode } from "../Utils/misc.js";

// в news не срабатывает. Возможно не обновляется отображение после изменения.  Нужно дорабатывать.

/**
 * @param {String} text
 * @param {Object} className
 * @return {React.ReactNode}
 */
const TextWithHTML = ({ text, className, ...rest }) => {
  const t = useTranslate();
  const [parseText, setParseText] = useState(null);

  // вся необходимая обработка текста (замена ссылок, bbcod-а "[spoiler]" и "///", обезвреживание)
  useEffect(() => {
    if (text) {
      setParseText(sanitizedData(parseTextWithBbcode(replaceUrlsOutsideTags(text))));
    }
  }, [text]);

  // После монтирования компонентов находим в обработанном тексте с помощью querySelector и querySelectorAll элементы,
  // которыми заменили bbcod "[spoiler]" и "///", добавляем для них обработчик кликов.
  // Делаем в отдельном  useEffect, так как в первом еще не смонтировались компоненты и querySelector querySelectorAll ничего не находят.
  useEffect(() => {
    if (parseText) {
      const spoilerLinks = document.querySelectorAll(".spoiler");
      const slashLink = document.querySelector(".slash");
      if (slashLink) {
        const id = slashLink.id;
        const afterSlash = document.getElementById(`div-${id}`);
        slashLink.textContent = t("showMore");
        if (afterSlash) {
          slashLink.onclick = () => {
            afterSlash.style.display = afterSlash.style.display === "none" ? "block" : "none";
            slashLink.textContent = afterSlash.style.display === "none" ? t("showMore") : t("hide");
          };
        }
      }
      if (spoilerLinks.length > 0) {
        spoilerLinks.forEach((element) => {
          const id = element.id;
          const spoiler = document.getElementById(`div-${id}`);
          element.onclick = () => {
            console.log(spoiler);
            spoiler.style.display = spoiler.style.display === "none" ? "block" : "none";
          };
        });
      }
    }
  }, [parseText]);

  return (
    <>
      {text && (
        <Typography className={className} {...rest}>
          <span dangerouslySetInnerHTML={parseText} />
        </Typography>
      )}
    </>
  );
};

TextWithHTML.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default TextWithHTML;
