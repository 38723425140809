import React, { createContext, useContext, useEffect, useState } from "react";
import { I18n } from "react-polyglot";
import en from "./locales/en.js";
import ru from "./locales/ru.js";
import fr from "./locales/fr.js";
import uk from "./locales/uk.js";
import uz from "./locales/uz.js";
import { useUserPreferences } from "../Data/UserPreferencesProvider.jsx";

/**
 * @return {Object}
 */
const selectLocales = (companyLocales) => {
  const newSelectLocales = {};

  for (const locale of companyLocales) {
    switch (locale) {
      case "uk":
        newSelectLocales["uk"] = uk;
        break;
      case "ru":
        newSelectLocales["ru"] = ru;
        break;
      case "fr":
        newSelectLocales["fr"] = fr;
        break;
      case "uz":
        newSelectLocales["uz"] = uz;
        break;
      default:
        newSelectLocales["en"] = en;
        break;
    }
  }

  return newSelectLocales;
};

/**
 * @param {string[]} localeCodes
 * @param {String} candidateLang
 * @returns {string}
 */
function getSupportedLang(localeCodes, candidateLang) {
  if (localeCodes.indexOf(candidateLang) > -1) {
    return candidateLang;
  } else {
    return localeCodes[0];
  }
}

/**
 * @typedef {Object} I18nProviderContext
 * @property {String} locale
 * @property {Function} setLocale
 * @property {string[]} localeCodes
 * @property {Function} setCompanyLocales
 */

/** @type {React.Context<I18nProviderContext>} */
const i18nContext = createContext({
  locale: "en",
  setLocale: () => {},
  localeCodes: [],
  setCompanyLocales: () => {},
});

/** @returns {I18nProviderContext} */
export const useLocale = () => useContext(i18nContext);

/** @param {React.ReactNode} children */
const I18nProvider = ({ children }) => {
  const { lang, setLang } = useUserPreferences();
  const [locales, setLocales] = useState({ uk, en, ru, fr, uz });
  const [localeCodes, setLocaleCodes] = useState(Object.keys(locales));
  const [messages, setMessages] = useState(locales[lang]);
  const [companyLocales, setCompanyLocales] = useState([]);

  /** @param {String} newLocale */
  const setLocale = (newLocale) => {
    setLang(newLocale);
  };

  // При сохранении company в BaseDataProvider и AuthProvider (из запросов /checkEmail, /company, /company/:urn) сохраняется companyLocales(языки из настройки компании), после чего меняется localeCodes (доступные языки)
  useEffect(() => {
    if (companyLocales.length > 0) {
      const newLocales = selectLocales(companyLocales);
      setLocales(newLocales);
      setLocaleCodes(Object.keys(newLocales));
    }
  }, [companyLocales]);

  // В зависимости от набора localeCodes меняем lang и setMessages
  useEffect(() => {
    if (lang && companyLocales.length > 0) {
      let langCandidate = getSupportedLang(localeCodes, lang);
      setLang(langCandidate);
      setMessages(locales[langCandidate]);
    } else if (lang) {
      setLang(lang);
      setMessages(locales[lang]);
    } else {
      setLang("en");
      setMessages(locales["en"]);
    }
  }, [lang, localeCodes]);

  if (!lang || !messages) return null;

  const value = {
    locale: lang,
    setLocale,
    localeCodes,
    setCompanyLocales,
  };

  return (
    <i18nContext.Provider value={value}>
      <I18n locale={lang} messages={messages}>
        {children}
      </I18n>
    </i18nContext.Provider>
  );
};

export default I18nProvider;
