import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles, withStyles } from "@material-ui/core";
import { useTranslate } from "react-polyglot";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import clsx from "clsx";
import TypographyArabic from "../../../../SharedComponents/TypographyArabic.jsx";
import { DEFAULT_LEARNED_PATCH, useLearnProcess } from "../../../../Providers/Data/LearnProcessProvider.jsx";
import Question from "./SharedComponents/Question.jsx";
import Appendix from "./SharedComponents/Appendix.jsx";

const gapRegex1 = /{[^}]+}/;
const gapRegex2 = /{[^}]+}/g;
const gapExtractRegex = /[^{}]+/;

/**
 * @param {string} str
 * @return {[]|*[]}
 */
function splitByBracesBlocks(str) {
  // Проверка наличия хотя бы одного блока в фигурных скобках
  if (!str.includes("{") || !str.includes("}")) {
    return [str];
  }

  const segments = [];
  const regex = /({[^{}]+})/g; // находит блоки {...}, но не вложенные

  let lastIndex = 0;
  let match;

  while ((match = regex.exec(str)) !== null) {
    // Добавляем текст до блока
    if (match.index > lastIndex) {
      segments.push(str.slice(lastIndex, match.index));
    }
    // Добавляем сам блок {...}
    segments.push(match[0]);
    lastIndex = regex.lastIndex;
  }

  // Добавляем остаток строки после последнего блока
  if (lastIndex < str.length) {
    segments.push(str.slice(lastIndex));
  }

  return segments;
}

const TypographyDivInline = withStyles(
  () => ({
    root: {
      display: "inline",
    },
  }),
  { name: "TypographyDivInline" },
)(({ rtl, ...rest }) => <Typography {...rest} component="div" style={{ direction: rtl ? "rtl" : "ltr" }} />);

TypographyDivInline.propTypes = {
  rtl: PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
  wrongGap: {
    backgroundColor: theme.palette.error.light,
    "&:focus": {
      backgroundColor: theme.palette.error.light,
    },
  },
  dropDown: {
    padding: "6px 0",
    minWidth: theme.spacing(4),
    "&.MuiSelect-select": {
      padding: "6px 0",
    },
  },
}));

/**
 * @param {String} sourceText Может выглядеть вот так:
 * "Выбери пропущенное слово {СловоА|СловоБ*|СловоВ*}. Ещё какой-то текст".
 * Звёздочками отмечены правильные ответы
 * @param {Number} lineIndex
 * @param {Function} onGapClick
 * @param {Boolean} rtl
 * @param {function} onUserVariantSelected
 * @param {boolean} showErrors
 * @returns {*}
 * @constructor
 */
const LineWithGaps = ({ sourceText, lineIndex, rtl, onUserVariantSelected, showErrors }) => {
  const classes = useStyles();
  const [userAnswers, setUserAnswers] = useState(
    /** @type {Object.<number, {text: string, rightAnswer: boolean}>} */ {},
  );
  const segments = splitByBracesBlocks(sourceText);
  const gapsTotal = segments.reduce((prev, current) => {
    if (gapRegex1.test(current)) return prev + 1;
    return prev;
  }, 0);

  /**
   * @param {number} index
   * @param {Array<{text: string, rightAnswer: boolean, lineIndex: number, index: number}>} dropDownItems
   * @return {function(...[*]=)}
   */
  const handleDropDownSelect = (index, dropDownItems) => (e) => {
    const rawValue = e.target.value;
    const value = { text: rawValue, rightAnswer: !!dropDownItems.find((item) => item.text === rawValue)?.rightAnswer };
    setUserAnswers({ ...userAnswers, [index]: value });
    onUserVariantSelected(lineIndex, index, gapsTotal, value);
  };

  return (
    <TypographyDivInline rtl={rtl}>
      {segments.map((segment, index) => {
        const gapSegments = segment.match(gapRegex1);
        /** @type {Array<{text: string, rightAnswer: boolean, lineIndex: number, index: number}>} */
        let dropDownItems;
        if (gapSegments?.length > 0) {
          dropDownItems = segment
            .match(gapExtractRegex)[0]
            .split("|")
            .map((w) => ({ text: w.replace(/\*/g, ""), rightAnswer: w.includes("*") }));
        }

        return (
          <TypographyDivInline key={index}>
            {!dropDownItems && <TypographyArabic component="span">{segment}</TypographyArabic>}
            {dropDownItems && (
              <Select
                classes={{ root: classes.dropDown }}
                className={clsx({ [classes.wrongGap]: showErrors && userAnswers[index]?.rightAnswer === false })}
                id={`${lineIndex}.${index}`}
                value={userAnswers[index]?.text || ""}
                onChange={handleDropDownSelect(index, dropDownItems)}
                IconComponent={() => null}
              >
                <MenuItem value="" />
                {dropDownItems.map((variant) => (
                  <MenuItem key={variant.text} value={variant.text}>
                    {variant.text}
                  </MenuItem>
                ))}
              </Select>
            )}
          </TypographyDivInline>
        );
      })}
    </TypographyDivInline>
  );
};

LineWithGaps.propTypes = {
  sourceText: PropTypes.string.isRequired,
  lineIndex: PropTypes.number.isRequired,
  rtl: PropTypes.bool,
  onUserVariantSelected: PropTypes.func.isRequired,
  showErrors: PropTypes.bool,
};

/**
 * @param {ExerciseType13} exercise
 * @param {Function} setCheckButtonDisabled
 * @param {String} exerciseTitle
 * @param {MutableRefObject<Function>} checkAnswerRef
 * @param {Object} rest
 * @returns {*}
 * @constructor
 */
const Exercise13 = ({ exercise, setCheckButtonDisabled, exerciseTitle, checkAnswerRef, ...rest }) => {
  const { saveUsersAnswer } = useLearnProcess();
  const t = useTranslate();
  const [showErrors, setShowErrors] = useState(false);
  const checkLineAnswerRef = useRef(/** @type {Object.<number, function>} */ {});
  const userSelectionsRef = useRef(
    /** @type {Object.<number, Object.<number, { text: string, rightAnswer: boolean }>>} */ {},
  );

  /** @type {ExerciseType13Answer} */
  const answer = exercise.answer;
  /** @type {string[]} */
  const textLines = useMemo(() => answer.text.split("\n"), [answer]);
  // Массив индексов строк в которых есть пропуски для заполнения
  /** @type {number[]} */
  const notEmptyLines = useMemo(() => {
    const result = [];
    for (let i = 0; i < textLines.length; i++) {
      if (gapRegex1.test(textLines[i])) {
        result.push(i);
      }
    }
    return result;
  }, [textLines]);

  //reset state
  useEffect(() => {
    setShowErrors(false);
    checkLineAnswerRef.current = {};
    userSelectionsRef.current = {};
  }, [answer]);

  checkAnswerRef.current = () => {
    let result = DEFAULT_LEARNED_PATCH;

    // Проверяем всё ли правильно заполнил пользователь
    /** @type {Array<{ text: string, rightAnswer: boolean }>} */
    const userAnswers = Object.values(userSelectionsRef.current)
      .flatMap((line) => Object.values(line))
      .filter((val) => typeof val === "object");
    const isAllOk = !userAnswers.some((answer) => !answer.rightAnswer);
    if (!isAllOk) {
      result = null;
      setShowErrors(true);
    }

    // Заполнение всей фразы из вопроса упражнения ответами ученика:
    let counter = 0;
    const entireUserAnswer = answer.text.replace(gapRegex2, () => {
      try {
        return userAnswers[counter].text;
      } finally {
        counter++;
      }
    });

    if (result === null) saveUsersAnswer(entireUserAnswer).then();
    else result = { ...DEFAULT_LEARNED_PATCH, payload: { answerValue: entireUserAnswer } };
    return result;
  };

  /**
   * @param {number} lineIndex
   * @param {number} index
   * @param {number} gapsPerLine
   * @param {{ text: string, rightAnswer: boolean }} value
   */
  const onUserVariantSelected = (lineIndex, index, gapsPerLine, value) => {
    setShowErrors(false);
    // Сначала добавляем данные пользователя в переменную
    const lineData = userSelectionsRef.current[lineIndex] || {};
    lineData.total = gapsPerLine;
    lineData[index] = value;
    userSelectionsRef.current[lineIndex] = lineData;
    // Теперь надо подсчитать ответы ученика. Если их количество равно общему количеству пропусков, то кнопку проверки
    // упражнения можно активировать
    for (let i = 0; i < textLines.length; i++) {
      if (!notEmptyLines.includes(i)) {
        continue;
      }
      const currentLine = userSelectionsRef.current[i];
      if (!currentLine) {
        setCheckButtonDisabled(true);
        return;
      }
      const lineTotal = currentLine.total;
      const onlyAnswers = Object.values(currentLine).filter((val) => typeof val === "object" && !!val.text);
      const filledCount = onlyAnswers.length;
      if (lineTotal > filledCount) {
        // в этой строке ещё не всё заполнено
        setCheckButtonDisabled(true);
        return;
      }
    }

    setCheckButtonDisabled(false);
  };

  return (
    <Box {...rest} data-component="Exercise13" display="flex" flexDirection="column" alignItems="center" height="100%">
      <Question
        exerciseQuestion={exercise.question}
        exerciseTitle={exerciseTitle}
        defaultTitle={t("Exercises.type13.title")}
        disableBottomSeparator
      />
      {exercise.appendix && exercise.appendix.pdf && <Appendix pdfUrl={exercise.appendix.pdf.url} />}
      <Box
        data-tag="source text"
        display="flex"
        flexDirection="column"
        alignItems={exercise.answer.rtl ? "flex-end" : "flex-start"}
        margin="25px 20px 0"
      >
        {textLines.map((line, index) => (
          <LineWithGaps
            key={index}
            sourceText={line}
            lineIndex={index}
            rtl={exercise.answer.rtl}
            onUserVariantSelected={onUserVariantSelected}
            showErrors={showErrors}
          />
        ))}
      </Box>
    </Box>
  );
};

Exercise13.propTypes = {
  checkAnswerRef: PropTypes.object.isRequired,
  exercise: PropTypes.object.isRequired,
  exerciseTitle: PropTypes.string,
  setCheckButtonDisabled: PropTypes.func.isRequired,
};

export default Exercise13;
