import React from "react";
import { TextField } from "mui-rff";
import Button from "@material-ui/core/Button";
import { useTranslate } from "react-polyglot";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import ReactFinalForm from "../../../SharedComponents/ReactFinalForm.jsx";
import { useAuth } from "../../../Providers/Auth/AuthProvider.jsx";
import useTitle from "../../../hooks/useTitle.js";
import FlexboxVertical from "../../../SharedComponents/FlexboxVertical.jsx";
import { useBaseData } from "../../../Providers/Data/BaseDataProvider.jsx";
import useCompanyPublicId from "../../../hooks/useCompanyPublicId.js";
import useFavicon from "../../../hooks/useFavicon.js";
// import { domains } from "../../../constants.js";

/** @param {String} className */
const Registration = ({ className }) => {
  // const { companyName, domain } = useCompanyPublicId();
  const { companyName } = useCompanyPublicId();
  const t = useTranslate();
  const { register, company2, checkEmailResponse } = useAuth();
  const { preselectedSubject, preselectedOffer } = useBaseData();
  const domain = window.location.host;

  useTitle("title.registration", { school: (company2 && company2.name) || companyName || domain });

  useFavicon();

  /**
   * @param {String} name
   * @param {String} phone
   * @param {String} password
   * @param {String} aboutMe
   * */
  const onSubmit = ({ name, phone, aboutMe, password }) => {
    if (aboutMe) {
      aboutMe = `${t("fullAge")}: ${aboutMe}`;
    }
    register(name, phone, aboutMe, password, preselectedSubject, preselectedOffer).then();
  };

  return (
    <>
      <Typography variant="subtitle1" align="center" color="primary">
        {t("title.createAccount")}
      </Typography>
      <ReactFinalForm
        onSubmit={onSubmit}
        formClassName={className}
        validate={({ aboutMe, password, confirmPassword }) => {
          const errors = {};
          if (confirmPassword !== password) {
            errors.confirmPassword = t("passwordsMatch");
          }
          if (domain === "dev.docker.web.skillzrun.com" && aboutMe) {
            if ((!!parseInt(aboutMe) && parseInt(aboutMe) < 10) || parseInt(aboutMe) > 99) {
              errors.aboutMe = t("errorAge");
            } else if (!parseInt(aboutMe)) {
              errors.aboutMe = t("notNumber");
            }
          }
          return errors;
        }}
      >
        <FlexboxVertical>
          <TextField
            name="name"
            label={domain !== "dev.docker.web.skillzrun.com" ? t("name") : t("firstLastName")}
            variant="outlined"
            fullWidth={false}
            fieldProps={{ defaultValue: checkEmailResponse?.name }}
            required
          />
          <TextField
            name="phone"
            label={domain !== "dev.docker.web.skillzrun.com" ? t("phone") : t("formatPhone")}
            variant="outlined"
            fullWidth={false}
            fieldProps={{ defaultValue: "" }}
          />
          {domain === "dev.docker.web.skillzrun.com" && (
            <TextField name="aboutMe" label={t("fullAge")} variant="outlined" fullWidth={false} required />
          )}
          <TextField
            name="password"
            type="password"
            label={t("password")}
            variant="outlined"
            fullWidth={false}
            required
          />
          <TextField
            name="confirmPassword"
            type="password"
            label={t("confirmPassword")}
            variant="outlined"
            fullWidth={false}
            required
          />
          <Button size="large" type="submit" variant="contained" color="primary">
            {t("registration")}
          </Button>
        </FlexboxVertical>
      </ReactFinalForm>
    </>
  );
};

Registration.propTypes = {
  className: PropTypes.string,
};

export default Registration;
