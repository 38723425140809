import React from "react";
import ScormDeckContent from "./ScormDeckContent.jsx";
import { useCourseProvider } from "../../../Providers/Data/CourseProvider.jsx";
import ClassicDeckContent from "./ClassicDeckContent.jsx";

/**
 * @returns {*}
 * @constructor
 */
const DeckContent = () => {
  const { currentDeck, currentBranch } = useCourseProvider();

  if (!currentDeck || !currentDeck.open || (currentBranch && !currentBranch.open)) {
    return null;
  }

  if (currentDeck.isScorm) {
    return <ScormDeckContent />;
  } else {
    return <ClassicDeckContent />;
  }
};

export default DeckContent;
