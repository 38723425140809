import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles, withStyles } from "@material-ui/core";
import { useTranslate } from "react-polyglot";
import Typography from "@material-ui/core/Typography";
import lodash from "lodash";
import ChipInput from "../../../../SharedComponents/ChipInput.jsx";
import TypographyArabic from "../../../../SharedComponents/TypographyArabic.jsx";
import ChipArabic from "../../../../SharedComponents/ChipArabic.jsx";
import { DEFAULT_LEARNED_PATCH, useLearnProcess } from "../../../../Providers/Data/LearnProcessProvider.jsx";
import Question from "./SharedComponents/Question.jsx";
import Appendix from "./SharedComponents/Appendix.jsx";

const gapRegex1 = /{[^}]+}/;
const gapRegex2 = /{[^}]+}/g;
const gapExtractRegex = /[^{}]+/;

const StyledArabicChip = withStyles((theme) => ({
  clickable: {
    "&:focus": {
      backgroundColor: theme.palette.grey[300],
    },
  },
  deletable: {
    "&:focus": {
      backgroundColor: theme.palette.grey[300],
    },
  },
  clickableColorPrimary: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  deleteIcon: {
    color: theme.palette.error.main,
    "&:hover": {
      color: theme.palette.error.main,
    },
  },
}))(ChipArabic);

const TypographyDivInline = withStyles(
  () => ({
    root: {
      display: "inline",
    },
  }),
  { name: "TypographyDivInline" },
)(({ rtl, ...rest }) => <Typography {...rest} component="div" style={{ direction: rtl ? "rtl" : "ltr" }} />);

TypographyDivInline.propTypes = {
  rtl: PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
  wordList: {
    boxShadow: "1px 1px 5px 1px rgba(0, 0, 0, 0.16) inset",
    backgroundColor: "white",
    alignSelf: "stretch",
    margin: 20,
    padding: 20,
    flexGrow: 1,
    direction: ({ rtl }) => {
      return rtl ? "rtl" : "ltr";
    },
    "&>*": {
      margin: "0 10px 10px 0",
    },
  },
  wrongGap: {
    backgroundColor: theme.palette.error.light,
    "&:focus": {
      backgroundColor: theme.palette.error.light,
    },
  },
  wrongGapDeleteIcon: {
    color: theme.palette.error.dark,
    "&:hover": {
      color: theme.palette.error.dark,
    },
  },
}));

/**
 * @param {String} sourceText
 * @param {Number} lineIndex
 * @param {Function} onGapClick
 * @param {?String} selectedGap
 * @param {Object} filledGaps
 * @param {Function} deleteGap
 * @param {Boolean} isErrorsVisible
 * @param {Object} classes
 * @param {('select'|'keyboard')} answerType
 * @param {Function} chipInputHandler
 * @param {Boolean} rtl
 * @returns {*}
 * @constructor
 */
const LineWithGaps = ({
  sourceText,
  lineIndex,
  onGapClick,
  selectedGap,
  filledGaps,
  deleteGap,
  isErrorsVisible,
  classes,
  answerType,
  chipInputHandler,
  rtl,
}) => {
  const segments = sourceText.split(gapRegex1);
  const gaps = sourceText.match(gapRegex2);

  /**
   * @param {Number} gapIndex
   * @param {string} value
   */
  const gapClickHandler = (gapIndex, value) => () => {
    if (answerType === "select" && filledGaps[gapIndex]) {
      return;
    }
    onGapClick({ [gapIndex]: value });
  };

  /** @type {Number[]} */
  const selectedGapVector = selectedGap && selectedGap.split(".").map((v) => Number.parseInt(v));

  return (
    <TypographyDivInline rtl={rtl}>
      {segments.map((segment, index) => {
        const gapIndex = gaps && (index < gaps.length ? `${lineIndex}.${index}` : null);
        const gapUserValue = gapIndex && filledGaps[gapIndex] && filledGaps[gapIndex].user;
        // разделяем варианты ответа на массив
        const rightVariants = (gapIndex && filledGaps[gapIndex] && filledGaps[gapIndex].source.split("|")) || [];

        return (
          <TypographyDivInline key={index}>
            <TypographyArabic component="span">{segment}</TypographyArabic>
            {answerType === "select" && gapIndex && (
              <StyledArabicChip
                arabicTransform={-5}
                classes={
                  isErrorsVisible && !rightVariants.includes(gapUserValue)
                    ? {
                        deletable: classes.wrongGap,
                        deleteIcon: classes.wrongGapDeleteIcon,
                      }
                    : undefined
                }
                color={
                  selectedGapVector && selectedGapVector[0] === lineIndex && selectedGapVector[1] === index
                    ? "primary"
                    : "default"
                }
                label={gapUserValue || "_______"}
                onClick={
                  !filledGaps[gapIndex] ? gapClickHandler(gapIndex, gaps[index].match(gapExtractRegex)[0]) : undefined
                }
                onDelete={
                  filledGaps[gapIndex]
                    ? () => {
                        deleteGap(gapIndex);
                      }
                    : undefined
                }
              />
            )}
            {answerType === "keyboard" && gapIndex && (
              <ChipInput
                onFocus={gapClickHandler(gapIndex, gaps[index].match(gapExtractRegex)[0])}
                onChange={chipInputHandler}
                value={gapUserValue || ""}
                error={
                  isErrorsVisible && !rightVariants.some((variant) => variant.equalsNoApostropheSensitive(gapUserValue))
                }
              />
            )}
          </TypographyDivInline>
        );
      })}
    </TypographyDivInline>
  );
};

LineWithGaps.propTypes = {
  answerType: PropTypes.string.isRequired,
  chipInputHandler: PropTypes.func,
  classes: PropTypes.object,
  deleteGap: PropTypes.func.isRequired,
  filledGaps: PropTypes.object,
  isErrorsVisible: PropTypes.bool,
  lineIndex: PropTypes.number.isRequired,
  onGapClick: PropTypes.func.isRequired,
  rtl: PropTypes.bool,
  selectedGap: PropTypes.string,
  sourceText: PropTypes.string.isRequired,
};

/**
 * @param {ExerciseType9|ExerciseType9Legacy} exercise
 * @param {Function} setCheckButtonDisabled
 * @param {String} exerciseTitle
 * @param {MutableRefObject<Function>} checkAnswerRef
 * @param {Object} rest
 * @returns {*}
 * @constructor
 */
const Exercise9 = ({ exercise, setCheckButtonDisabled, exerciseTitle, checkAnswerRef, ...rest }) => {
  const classes = useStyles({ rtl: exercise.answer.rtl });
  const { saveUsersAnswer } = useLearnProcess();
  const t = useTranslate();
  const [gapsCount, setGapsCount] = useState(0);
  const [words, setWords] = useState([]);
  const [currentSourceGap, setCurrentSourceGap] = useState(null);
  const [filledGaps, setFilledGaps] = useState({});
  const usedWordsRef = useRef([]);
  const [isErrorsVisible, setErrorsVisible] = useState(false);
  const [selectedWordIndex, setSelectedWordIndex] = useState(-1);

  /** @type {ExerciseType9Answer|ExerciseType9Legacy} */
  const answer = exercise.answer || exercise;

  //reset state
  useEffect(() => {
    const gaps = answer.text.match(gapRegex2) || [];
    const extraWords = answer.extraWords;
    let words_ = [...gaps.flatMap((gap) => gap.match(gapExtractRegex)[0].split("|")), ...extraWords];
    words_ = lodash.shuffle(words_);
    setGapsCount(gaps.length);
    setWords(words_);
    setCurrentSourceGap(null);
    setFilledGaps({});
    setErrorsVisible(false);
    usedWordsRef.current = [];
  }, [answer]);

  // update check button state
  useEffect(() => {
    setCheckButtonDisabled(Object.keys(filledGaps).length !== gapsCount);
  }, [filledGaps, gapsCount, setCheckButtonDisabled]);

  checkAnswerRef.current = () => {
    let result = DEFAULT_LEARNED_PATCH;
    let entireUserAnswer = answer.text;
    let counter = 0;
    Object.values(filledGaps).forEach(
      /** @param {{source: String, user: String}} theGap
       * @param {number} i */
      (theGap, i) => {
        // Заполнение всей фразы из вопроса упражнения ответами ученика:
        entireUserAnswer = entireUserAnswer.replace(new RegExp(`{(${theGap.source.escapeForRegex()})}`), (match) => {
          try {
            // Поскольку эта лямбда дёргается функцией .replace() столько раз сколько элементов в массиве filledGaps,
            // то заменять исходный (правильный) вариант нужно только в случае, если порядковый номер (i)
            // пробела (theGap) равен счётчику проходов (counter)
            if (i === counter) return theGap.user;
            else return match;
          } finally {
            counter++;
          }
        });

        // ==== Теперь, собственно, проверка правильности ответа: ====
        const rightVariants = theGap.source.split("|"); // разделяем варианты ответа на массив

        // Если среди вариантов нет совпадений с пользовательским ответом, то включаем показ ошибки и в результат
        // для "патчинга" упражнения записываем null
        if (!rightVariants.some((rightVariant) => rightVariant.equalsNoApostropheSensitive(theGap.user))) {
          result = null;
          setErrorsVisible(true);
        }
        // ===========================================================
      },
    );
    if (result === null) saveUsersAnswer(entireUserAnswer).then();
    else result = { ...DEFAULT_LEARNED_PATCH, payload: { answerValue: entireUserAnswer } };
    return result;
  };

  /** @param {Object} gapData */
  const sourceGapClickHandler = (gapData) => {
    if (selectedWordIndex === -1) {
      if (currentSourceGap === null) {
        setCurrentSourceGap(gapData);
      } else {
        const currentGapIndex = Object.keys(currentSourceGap)[0];
        const dataGapIndex = Object.keys(gapData)[0];
        if (answer.answerType === "select" && currentGapIndex === dataGapIndex) {
          setCurrentSourceGap(null);
        } else {
          setCurrentSourceGap(gapData);
        }
      }
    } else {
      // here is a word already selected by user
      const key = Object.keys(gapData)[0];
      const wordIndex = selectedWordIndex;
      const word = words[wordIndex];
      usedWordsRef.current.push(word);
      words.splice(wordIndex, 1);
      setWords([...words]);
      filledGaps[key] = { source: gapData[key].trim(), user: word };
      setFilledGaps({ ...filledGaps });
      setSelectedWordIndex(-1);
    }
  };

  /** @param {String} word
   * @param {Number} index */
  const wordClickHandler = (word, index) => () => {
    if (!currentSourceGap) {
      if (selectedWordIndex === index) {
        setSelectedWordIndex(-1);
      } else {
        setSelectedWordIndex(index);
      }
    } else {
      const key = Object.keys(currentSourceGap)[0];
      const wordIndex = words.indexOf(word);
      usedWordsRef.current.push(words[wordIndex]);
      words.splice(wordIndex, 1);
      setWords([...words]);
      filledGaps[key] = { source: currentSourceGap[key].trim(), user: word };
      setFilledGaps({ ...filledGaps });
      setCurrentSourceGap(null);
    }
  };

  /** @param {String} customText */
  const chipInputHandler = (customText) => {
    if (!currentSourceGap) {
      return;
    }
    const key = Object.keys(currentSourceGap)[0];
    if (customText === "") {
      delete filledGaps[key];
    } else {
      filledGaps[key] = { source: currentSourceGap[key], user: customText };
    }
    setFilledGaps({ ...filledGaps });
    setErrorsVisible(false);
  };

  /** @param {number} gapIndex */
  const deleteGapHandler = (gapIndex) => {
    const gapToDelete = filledGaps[gapIndex];
    const word = gapToDelete.user;
    const wordIndex = usedWordsRef.current.indexOf(word);
    words.push(word);
    usedWordsRef.current.splice(wordIndex, 1);
    delete filledGaps[gapIndex];
    setCurrentSourceGap({ [gapIndex]: gapToDelete.source });
    setErrorsVisible(false);
    setFilledGaps({ ...filledGaps });
    setWords([...words]);
    setSelectedWordIndex(-1);
  };

  const textLines = answer.text.split("\n");
  const answerType = answer.answerType;

  return (
    <Box {...rest} data-component="Exercise9" display="flex" flexDirection="column" alignItems="center" height="100%">
      <Question
        exerciseQuestion={exercise.question}
        exerciseTitle={exerciseTitle}
        defaultTitle={t(`Exercises.type9.title.${answerType}`)}
        disableBottomSeparator
      />
      {exercise.appendix && exercise.appendix.pdf && <Appendix pdfUrl={exercise.appendix.pdf.url} />}
      <Box
        data-tag="source text"
        display="flex"
        flexDirection="column"
        alignItems={exercise.answer.rtl ? "flex-end" : "flex-start"}
        margin="25px 20px 0"
      >
        {textLines.map((line, index) => (
          <LineWithGaps
            key={index}
            sourceText={line}
            lineIndex={index}
            onGapClick={sourceGapClickHandler}
            selectedGap={currentSourceGap && Object.keys(currentSourceGap)[0]}
            filledGaps={filledGaps}
            deleteGap={deleteGapHandler}
            isErrorsVisible={isErrorsVisible}
            classes={classes}
            answerType={answerType}
            chipInputHandler={chipInputHandler}
            rtl={exercise.answer.rtl}
          />
        ))}
      </Box>
      {answerType === "select" && (
        <Box className={classes.wordList}>
          {words.map((word, index) => (
            <StyledArabicChip
              key={index}
              label={word}
              onClick={wordClickHandler(word, index)}
              color={selectedWordIndex === index ? "primary" : "default"}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

Exercise9.propTypes = {
  checkAnswerRef: PropTypes.object.isRequired,
  exercise: PropTypes.object.isRequired,
  exerciseTitle: PropTypes.string,
  setCheckButtonDisabled: PropTypes.func.isRequired,
};

export default Exercise9;
