import React, { useEffect, useState } from "react";
import {
  Box,
  Link,
  makeStyles,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  ButtonBase,
} from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";
import { useTranslate } from "react-polyglot";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import CloseIcon from "@material-ui/icons/Close";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useHistory } from "react-router-dom";
import { subDays, endOfDay } from "date-fns";
import { IS_APP_STUDYLESS } from "../../../constants.js";
import useExtractRouteParamInt from "../../../hooks/useExtractRouteParamInt.js";
import { useEvents } from "../../../Providers/Data/EventsProvider.jsx";
import useDateUtils from "../../../hooks/useDateUtils.js";
import { useDialogs } from "../../../Providers/Common/DialogsProvider.jsx";
import { SNACKBAR_POSITION } from "../../../Providers/Common/MySnackbar.jsx";
import FlexboxHorizontal from "../../../SharedComponents/FlexboxHorizontal.jsx";
import PrimaryOutlinedRoundedButton from "../../../SharedComponents/PrimaryOutlinedRoundedButton.jsx";
import PrimaryRoundedButton from "../../../SharedComponents/PrimaryRoundedButton.jsx";
import avatar from "../../../assets/ic_avatar.svg";
import borderchank from "../../../assets/borderchank.svg";
import FlatPrimaryButton from "../../../SharedComponents/FlatPrimaryButton.jsx";
import FlatGreyButton from "../../../SharedComponents/FlatGreyButton.jsx";
import UniversalVideoPlayer from "../../../SharedComponents/UniversalVideoPlayer.jsx";
import { convertToCompactUTC } from "../../../Utils/misc.js";
import { useBaseData } from "../../../Providers/Data/BaseDataProvider.jsx";

const useStyles = makeStyles((theme) => ({
  eventHead: {
    boxShadow: "2px 0px 4px rgba(0, 0, 0, 0.1)",
    display: "flex",
    alignItems: "center",
    color: theme.palette.secondary.dark,
    padding: 20,
    fontSize: 18,
    fontWeight: 500,
    textAlign: "center",
    "&>*": {
      marginRight: 40,
    },
  },
  closeButton: {
    marginLeft: "auto",
    marginRight: 0,
  },
  eventStatus: {
    fontSize: 18,
    fontWeight: 500,
    textTransform: "uppercase",
  },
  videoPlayerWrapper: {
    width: 375,
    margin: "16px auto",
    "&>div": {
      width: "100%",
    },
  },
  eventContent: {
    padding: 20,
    backgroundColor: grey[100],
  },
  eventRows: {
    columnCount: 2,
    pageBreakInside: "avoid",
    breakInside: "avoid",
  },
  eventInfo: {
    color: theme.palette.secondary.dark,
    textTransform: "uppercase",
    marginBottom: 12,
    float: "left",
  },
  tags: {
    textTransform: "none",
  },
  marginLeft: {
    marginLeft: 16,
  },
  eventBtn: {
    minWidth: 300,
    [theme.breakpoints.between(1024, 1320)]: {
      minWidth: 180,
    },
  },
  icon: {
    width: 50,
    height: 50,
    objectFit: "cover",
    marginRight: 23,
    borderRadius: "50%",
    backgroundColor: "white",
  },
  description: {
    color: theme.palette.secondary.dark,
  },
  link: {
    marginTop: 10,
    textAlign: "center",
  },
  notice: {
    color: theme.palette.secondary.dark,
    textAlign: "center",
    marginTop: 20,
  },
  deckLinkBox: {
    height: 120,
    width: "100%",
    marginTop: 20,
    border: "2px solid",
    borderRadius: 18,
    borderColor: theme.palette.primary.main,
  },
  imageDeckBox: {
    position: "relative",
    width: 96,
    height: 96,
    flexShrink: 0,
  },
  imageDeck: {
    position: "absolute",
    display: "block",
    top: 6,
    left: 6,
    width: 84,
    height: 84,
    borderRadius: 17,
    objectFit: "cover",
  },
  imageDeckBorder: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 96,
    height: 96,
    objectFit: "fill",
  },
  deckName: {
    flexGrow: 2,
    color: theme.palette.secondary.dark,
    fontSize: 18,
    fontWeight: 500,
    marginLeft: 20,
    textAlign: "start",
  },
  deckButton: {
    color: theme.palette.secondary.dark,
    marginRight: 20,
  },
  dialogContent: {
    padding: "35px !important",
    color: theme.palette.secondary.dark,
    textAlign: "center",
    fontSize: 18,
  },
  dialogActions: {
    margin: 0,
    padding: 0,
    width: "100%",

    "& .MuiButton-root": {
      width: "50%",
    },
  },
}));

/** */
const Event = () => {
  const classes = useStyles();
  const { success } = useDialogs();
  const t = useTranslate();
  const { format } = useDateUtils();
  const history = useHistory();
  const { sortedEvents, markInterested, markVisit } = useEvents();
  const eventId = useExtractRouteParamInt("/events/:eventId", "eventId");
  const [selectedEvent, setSelectedEvent] = useState(/** @type {?SkillzrunEvent} */ null);
  const [eventDeck, setEventDeck] = useState(/** @type {?Deck} */ null);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const { branches } = useBaseData();

  // find event in event arrays
  useEffect(() => {
    if (IS_APP_STUDYLESS) {
      setSelectedEvent(sortedEvents.find((event) => event.id === eventId));
    } else {
      setSelectedEvent(sortedEvents.flatMap((sEvents) => sEvents.events).find((event) => event.id === eventId));
    }
  }, [eventId, sortedEvents]);

  //changes the event deck when the selectedEvent changes
  useEffect(() => {
    if (selectedEvent?.deck) {
      const branch = branches.find((branch) => branch.decks.find((deck) => deck.id === selectedEvent.deck.id));
      setEventDeck(branch.decks.find((deck) => deck.id === selectedEvent.deck.id));
    } else {
      setEventDeck(null);
    }
  }, [selectedEvent]);

  /** */
  const interestedReq = async () => {
    /** @type {InterestedPatchResponse} */
    const resp = await markInterested(eventId);

    if (resp) {
      success(resp.message, SNACKBAR_POSITION.TOP_CENTER);
    }
  };

  /** */
  const sendMarkVisit = async () => {
    await markVisit(eventId);
  };

  /** */
  const interestedButtonHandler = () => {
    if (selectedEvent.interested) {
      setPopupOpen(true);
    } else {
      interestedReq();
    }
  };

  /** */
  const popupCloseHandler = () => {
    setPopupOpen(false);
  };

  if (!selectedEvent) {
    return null;
  }

  const date = new Date(selectedEvent.date);
  const dateNow = new Date();
  const datePlusDay = new Date(selectedEvent.date);
  datePlusDay.setDate(date.getDate() + 1);

  const datePlusHour = new Date(selectedEvent.date);
  datePlusHour.setTime(date.getTime() + 3600000);

  // false - если день события закончился, true - любое время до конца дня события
  const beforeEndDay = endOfDay(date) > dateNow;

  return (
    <Box minWidth="320px">
      <Box className={classes.eventHead}>
        <Typography className={classes.eventStatus} color="primary">
          {selectedEvent.online ? "online" : "offline"}
        </Typography>
        <Typography>
          {format(date, "EEEEEE")} <br /> <b>{format(date, "dd")}</b>
        </Typography>
        <Typography variant="h6">{selectedEvent.name}</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={() => history.push("/events")}>
          <CloseIcon />
        </IconButton>
      </Box>
      {selectedEvent.video?.url && (
        <Box className={classes.videoPlayerWrapper}>
          <UniversalVideoPlayer url={selectedEvent.video.url} height={225} />
        </Box>
      )}
      <Box className={classes.eventContent}>
        <FlexboxHorizontal>
          {selectedEvent.leader && (
            <img className={classes.icon} src={selectedEvent.leader.photo?.url || avatar} alt="" />
          )}
          <div className={classes.eventRows}>
            {selectedEvent.leader && (
              <div className={classes.eventInfo}>
                {t("Events.leader")}:{" "}
                <span className={classes.marginLeft}>
                  <b>{selectedEvent.leader.name}</b>
                </span>
              </div>
            )}
            <div className={classes.eventInfo}>
              {t("Events.group")}: <span className={classes.marginLeft}>{selectedEvent.registeredPersons}</span> /{" "}
              <b>{t("Events.persons", { smart_count: selectedEvent.persons })}</b>
            </div>
            <div className={classes.eventInfo}>
              {t("Events.startTime")}: <b className={classes.marginLeft}>{format(date, "HH:mm")}</b>
            </div>
            {selectedEvent.tags[0] && (
              <div className={classes.eventInfo}>
                {t("Events.tag")}:{" "}
                <b className={classes.marginLeft}>
                  <span className={classes.tags}>{selectedEvent.tags.map((tag) => tag.name).join(", ")}</span>
                </b>
              </div>
            )}
          </div>
        </FlexboxHorizontal>
        <Typography className={classes.description}>{selectedEvent.description}</Typography>
      </Box>
      <Typography className={classes.link}>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href={`https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
            selectedEvent.name,
          )}&dates=${convertToCompactUTC(date.toISOString())}/${convertToCompactUTC(
            datePlusHour.toISOString(),
          )}&details=${encodeURIComponent(selectedEvent.description)}&sf=true&output=xml`}
        >
          &#128198; {t("Events.calendarLink")}
        </Link>
      </Typography>
      {eventDeck && (
        <Box className={classes.deckLinkBox}>
          <FlexboxHorizontal height="96px" alignItems="center" marginTop="10px" marginLeft="10px">
            {(eventDeck.illustrationImage || eventDeck.illustrationImageUrl) && (
              <ButtonBase className={classes.imageDeckBox} onClick={() => history.push(`/learn/${eventDeck.id}`)}>
                <img
                  className={classes.imageDeck}
                  src={eventDeck.illustrationImage.url || eventDeck.illustrationImageUrl}
                  alt=""
                />
                <img className={classes.imageDeckBorder} src={borderchank} alt="" />
              </ButtonBase>
            )}
            <ButtonBase className={classes.deckName} onClick={() => history.push(`/learn/${eventDeck.id}`)}>
              <Typography>{eventDeck.name}</Typography>
            </ButtonBase>
            <IconButton className={classes.deckButton} onClick={() => history.push(`/learn/${eventDeck.id}`)}>
              <ArrowForwardIosIcon />
            </IconButton>
          </FlexboxHorizontal>
        </Box>
      )}
      <FlexboxHorizontal
        data-tag="Event buttons"
        justifyContent={selectedEvent.meetingUrl ? "space-between" : "flex-end"}
        marginTop="20px"
      >
        {selectedEvent.meetingUrl && (
          <PrimaryOutlinedRoundedButton
            className={classes.eventBtn}
            component="a"
            href={selectedEvent.meetingUrl}
            target="_blank"
            disabled={!selectedEvent.interested || selectedEvent.twentyFour || !beforeEndDay}
            onClick={() => sendMarkVisit()}
          >
            {t("Events.enter")}
          </PrimaryOutlinedRoundedButton>
        )}
        <PrimaryRoundedButton
          className={classes.eventBtn}
          startIcon={selectedEvent.interested ? <CheckRoundedIcon /> : undefined}
          onClick={interestedButtonHandler}
          disabled={!selectedEvent.twentyFour && (selectedEvent.interested || dateNow > datePlusDay)}
        >
          {t("Events.interested")}
        </PrimaryRoundedButton>
      </FlexboxHorizontal>
      <Box className={classes.notice}>
        {selectedEvent.meetingUrl && selectedEvent.interested && IS_APP_STUDYLESS && dateNow < date && (
          <Typography>
            {t("Events.registrationNotice", { date_and_time: format(subDays(date, 1), "EEEEEE dd, HH:mm") })}
          </Typography>
        )}
        {!selectedEvent.meetingUrl && selectedEvent.interested && IS_APP_STUDYLESS && dateNow < date && (
          <Typography>
            {t("Events.registrationNoticeNoUrl", { date_and_time: format(subDays(date, 1), "EEEEEE dd, HH:mm") })}
          </Typography>
        )}
        {dateNow > date && dateNow < datePlusDay && <Typography>{t("Events.eventHasStarted")}</Typography>}
      </Box>

      <Dialog open={isPopupOpen} onClose={popupCloseHandler} fullWidth maxWidth="xs">
        <DialogContent className={classes.dialogContent}>
          <Typography>{t("Events.cancelRegistration")}</Typography>
        </DialogContent>
        <DialogActions className={classes.dialogActions} disableSpacing>
          <FlatGreyButton onClick={popupCloseHandler}>{t("no")}</FlatGreyButton>
          <FlatPrimaryButton
            onClick={() => {
              interestedReq();
              popupCloseHandler();
            }}
            className={classes.dialogOkButton}
          >
            {t("yes")}
          </FlatPrimaryButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Event;
